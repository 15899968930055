import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface IManager {
    name?: string,
    username?: string,
    role?: 'admin' | 'manager'
}
export interface ManagerSlice {
    manager: IManager
}

const initialState: ManagerSlice = {
    manager: {}
}

export const managerSlice = createSlice({
    name: 'manager-slice',
    initialState,
    reducers: {
        setManagerAdmin(state, action: PayloadAction<IManager>) {
            state.manager = action.payload
        }
    }
})

export const managerActions = managerSlice.actions
export const managerReducer = managerSlice.reducer