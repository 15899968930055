import {
    CreateClientData,
    CreateClientServerResponse,
    IUser,
    ServerResponse,
    VerifyDataCheck,
    VerifyServerResponse,
    ISubscriber
} from '../../models/models';
import {
    CreateSubscriberData,
    UpdateSubscriberData,
    UpdateSubscriberServerResponse,
    CreateSubscriberServerResponse,
    AutoAuthServerResponse, PasscodeLoginSubscriberData
} from '../../models/subscribers/models'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {LoginSubscriberData, LoginSubscriberServerResponse} from "../../models/subscribers/models";

export const myApi = createApi({
    reducerPath: 'api/insurance',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:6080/' : 'https://api.365asig.md/',
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('token') ? JSON.parse(<string>localStorage.getItem('token')) : ''
            headers.set('Content-Type', 'application/json')
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    refetchOnFocus: true,
    tagTypes: ['Data'],
    endpoints: build => ({
        // @todo delete after
        getPrice: build.query<IUser[], string>({
            query: (search: string) => ({
                url: `search/users`,
                params: {
                    q: search,
                    per_page: 10
                }
            }),
            transformResponse: (response: ServerResponse<IUser>) => response.items
        }),
        
        verifyDates: build.mutation<VerifyServerResponse, VerifyDataCheck>({
            query: (payload: VerifyDataCheck) => ({
                url: `verify`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Data']
        }),
        
        createClient: build.mutation<CreateClientServerResponse, CreateClientData>({
            query: (payload: CreateClientData) => ({
                url: `clients/create`,
                method: 'POST',
                body: payload
            })
        }),

        authSubscriber: build.query<ISubscriber, string>({
            query: () => ({
                url: `auth/subscriber`,
            })
        }),
        
        loginSubscriber: build.mutation<LoginSubscriberServerResponse, LoginSubscriberData>({
            query:(payload: LoginSubscriberData) => ({
                url: `subscribers/login`,
                method: 'POST',
                body: payload
            })
        }),

        autoAuthSubscriber: build.query<AutoAuthServerResponse, string>({
            query: (secret: string) => ({
                url: `subscribers/auto-auth`,
                params: {
                    secret: secret
                }
            })
        }),

        passcodeLogin: build.mutation<AutoAuthServerResponse, PasscodeLoginSubscriberData>({
            query:(payload: PasscodeLoginSubscriberData) => ({
                url: `subscribers/auto-auth-passcode`,
                method: 'POST',
                body: payload
            })
        }),

        createSubscriber: build.mutation<CreateSubscriberServerResponse, CreateSubscriberData>({
            query: (payload: CreateSubscriberData) => ({
                url: `subscribers/create`,
                method: 'POST',
                body: payload
            })
        }),

        updateSubscriberField: build.mutation<UpdateSubscriberServerResponse, UpdateSubscriberData>({
            query: (payload: UpdateSubscriberData) => ({
                url: `subscribers/update`,
                method: 'PUT',
                body: payload
            })
        }),
        
    }) 
})

export const {
    useGetPriceQuery, 
    useVerifyDatesMutation, 
    useCreateClientMutation, 
    useAuthSubscriberQuery,
    useAutoAuthSubscriberQuery,
    useLoginSubscriberMutation,
    useCreateSubscriberMutation,
    useUpdateSubscriberFieldMutation,
    usePasscodeLoginMutation,
} = myApi


