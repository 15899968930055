import Label from "../elements/Label";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../hooks/redux";
import DatePicker from "react-datepicker";
import {useUpdateSubscriberFieldMutation} from "../../store/api/my.api";
import BasicButton from "../elements/buttons/BasicButton";
import {useActions} from "../../hooks/actions";
import useToast from "../../hooks/toast"
import { MdAdd, MdClear } from 'react-icons/md'

export function ExpirationDateModule() {
    
    const { expirationDates } = useAppSelector(state => state.account)
    
    const { 
        setExpirationDateValueAccount, 
        setExpirationDateErrorAccount,
        updatedExpirationDateValueAccount
    } = useActions()
    
    const [isErrorDates, setIsErrorDates] = useState<boolean>(false)
    useEffect(() => {
        if (isErrorDates) {
            setExpirationDateErrorAccount('Nu puteti seta mai mult de 3!')
        } else {
            setExpirationDateErrorAccount(null)
        }
    }, [ isErrorDates, setExpirationDateErrorAccount])

    const onChangeExpirationDate = (date: Date, key: number) => {
        updatedExpirationDateValueAccount({key, value: date})
    }
    const {toastSuccess, toastError} = useToast()
    const [updateSubscriberField, {isLoading}] = useUpdateSubscriberFieldMutation()

    const addExpirationDate = () => {
        const newExpirationDates: Array<Date> = expirationDates.value.map((date: Date) => date);
        if (newExpirationDates.length >= 3) {
            setIsErrorDates(true)
            return
        }
        newExpirationDates.push(new Date())
        setExpirationDateValueAccount(newExpirationDates)
    }
    
    const removeExpirationDate = () => {
        const newExpirationDates: Array<Date> = expirationDates.value.map((date: Date) => date);
        newExpirationDates.pop()
        setExpirationDateValueAccount(newExpirationDates)
    }

    const clickBtn = async () => {
        await updateSubscriberField({
            key: 'expiration_dates',
            value: expirationDates.value
        }).unwrap()
        .then(() => {
            toastSuccess('success')
        }).catch(() => {
            toastError('Server error')
        })
    }
    
    
    const formDatePicker = () => {
        return (
            <div className={' flex space-x-2'}>
                <div className={'w-5/6'}>
                    {
                        expirationDates.value.map((dateState: Date, key: number) => {
                            return (
                                    <DatePicker
                                        key={'expDate' + key}
                                        dateFormat="dd.MM.yyyy"
                                        selected={dateState}
                                        locale="ro"
                                        onChange={(date:Date) => onChangeExpirationDate(date, key)}
                                        className={
                                        `${expirationDates.error ?  'border-error1' : 'border-primaryBlbg'} 
                                        mb-4 w-full 
                                        h-[48px] 
                                        bg-white 
                                        p-4 
                                        border-[1.5px]  
                                        rounded-[10px] 
                                        text-sm placeholder-gray 
                                        cursor-pointer 
                                        hover:border-primary2 
                                        focus:border-primeBlue 
                                        focus:text-black
                                        `}
                                    />
                            )
                        })
                    }
                    <span className="text-error1 text-xs">{expirationDates.error}</span>
                </div>
                <div className={' flex flex-col justify-end mb-4'}>
                    { expirationDates.value.length > 1 && <BasicButton rounded={true} disabled={isLoading} label={<MdClear size={24} />} onClick={removeExpirationDate} /> }
                </div>
            </div>
        )
    }

    const showButtons = () => {
        return (
            <div className="flex justify-between">
                <div className="w-60">
                    <BasicButton disabled={isLoading} label="Actualizează!" onClick={clickBtn} />
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className={'flex space-x-2'}>
                <div className={'w-5/6'}>
                    <Label name="Setați data de expirare a asigurărilor auto și veți primi o notificare prin e-mail sau telefon referitoare la acestea." />
                </div>
                { expirationDates.value.length < 3 && <BasicButton rounded={true} disabled={isLoading} label={<MdAdd size={24} />} onClick={addExpirationDate} />}
            </div>
            
            {formDatePicker()}
            {showButtons()}
        </div>
    )
}
