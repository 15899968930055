import React from "react";
import { ICheckbox } from "../../models/models";

const Checkbox = ({id, name, checked, value, label, onChange}: ICheckbox) => {
    return (
        <div className="checkbox-rect">
            <input type="checkbox" onChange={onChange} id={id} name={name} checked={checked} value={value} />
            <label className=" font-light" htmlFor={id}>{label}</label>
        </div>
        
    )
}

export default Checkbox