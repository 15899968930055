import React from "react";
import BasicButton from "../components/elements/buttons/BasicButton";

const useModal = (initValue: boolean = false) => {
    const [showModal, setShowModal] = React.useState<boolean>(initValue)
    
    const renderModal = (
        <>
            <button
                className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
            >
                Open regular modal
            </button>
            {showModal ? (
                <>
                    <div
                        className=" text-neutral justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-primary2 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        TERMENI SI CONDITII GDPR
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-gray h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className={'text-sm leading-relaxed'}>
                                        <p className={'font-semibold'}>CONDITII GENERALE PENTRU UTILIZAREA SITE-ULUI 365ASIG.MD</p>
                                        <p className={'font-semibold'}>ACCEPTAREA CONDITIILOR</p>
                                        <p>Prin accesarea acestui site web și/sau a oricărei pagini a acestuia sunteți de acord cu aceste condiții de utilizare. Dacă nu sunteți de acord cu acești termeni și condiții de utilizare, nu accesați acest site.</p>
                                        <p className={'font-semibold'}>DESCRIEREA SERVICIILOR</p>
                                        <p>Site-ul nostru va pune la dispoziție informațiile din acest site web în scop informativ general și nu garantează exactitatea lor la un moment dat, deși se va încerca pe cât posibil ca la publicarea lor pe site toate informațiile să fie exacte.</p>
                                        <p className={'font-semibold'}>INTRODUCERE</p>
                                        <p>Prezentele Conditii Generale definesc condițiile de utilizare a site-ului 365ASIG.MD de către potențialii vizitatori sau clienți. Accesând și navigând pe acest site, acceptați termenii de utilizare descriși în continuare.</p>
                                        <p className={'font-semibold'}>DREPTURILE ASUPRA CONȚINUTULUI SITE-ULUI</p>
                                        <p>S.C. GADMAXIMUS S.R.L (denumită în continuare 365ASIG.MD), nu va transfera titlul de proprietate asupra aplicațiilor software.</p>
                                        <p>365ASIG.MD deține drepturi complete și depline asupra titlului de proprietate și prin aceasta toate drepturile de autor și cele brevetate. Nu aveți dreptul să redistribuiți, vindeți, decompilați, dezasamblați aplicația software într-o formă perceptibilă de către oameni.</p>

                                    </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-primary2 rounded-b">
                                    <div className={'w-[150px]'}><BasicButton label={'Inchide!'} onClick={() => setShowModal(false)} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    )
    
    return {setShowModal, renderModal}
}

export default useModal
