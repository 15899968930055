import useLocalStorage from "./localStorage";
import {useActions} from "./actions";
import {useNavigate} from "react-router-dom";


export const useLogout = () => {
    const { removeItem } = useLocalStorage()
    const {setIsAuth} = useActions()
    const navigate = useNavigate();

    const logoutAdminPanel = () => {

        removeItem('token')
        removeItem('_grecaptcha')
        navigate('/admin')
    }
    const logoutSubscribe = () => {
        setIsAuth(false)
        removeItem('token')
        navigate('/')
    }
    return {
        logoutAdminPanel,
        logoutSubscribe,
    }
}