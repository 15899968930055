import React from "react";
import { IButton } from "../../../models/models";

const BasicButton = ({label, disabled, onClick, rounded}: IButton) => {
    return (
        <button
            disabled={disabled}
            className={` ${rounded ? 'rounded-full' : 'rounded-lg'} h-[48px] w-auto px-4 text-sm bg-primary2 hover:bg-primeBlue font-semibold text-white flex items-center justify-center`}
            onClick={onClick}>
            {label}
        </button>
    )
}

export default BasicButton
