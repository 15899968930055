import { IButton, IRotate } from "../../../models/models";
import Arrow from "../../icons/Arrow";

interface IShowMoreLess extends IButton, IRotate {}

const ShowMoreLessButton = ({label, onClick, rotate}: IShowMoreLess) => {

    return (
            <button onClick={onClick} className=" cursor-pointer relative px-4 my-4 text-sm text-primeBlue">
                {label}
                <div className=" absolute right-0 top-2 ">
                    <Arrow rotate={rotate} />
                </div>
            </button>
    )
}

export default ShowMoreLessButton