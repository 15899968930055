import Sidebar from "../../components/admin/Sidebar";
import Navbar from "../../components/admin/Navbar";
import "@fortawesome/fontawesome-free/css/all.min.css";
import useAuthManager from "../../hooks/auth/useAuthManager";

export function DashboardPageAdmin () {
    
    useAuthManager()
    
    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <Navbar />
                <div className="relative bg-pink-600 md:pt-32 pb-32 pt-12">
                    <div className="px-4 md:px-10 mx-auto w-full">
                        <p>Hi</p>
                    </div>
                </div>
            </div>
        </>
    )
}
