import React from "react";
import {Link} from "react-router-dom";
import logo from '../../src/assets/logo-01.png'
import AppLink from "./elements/AppLink";
import { useLocation } from "react-router-dom";
import {useAppSelector} from "../hooks/redux";
import useLocalStorage from "../hooks/localStorage";
import {useActions} from "../hooks/actions";
import {useLogout} from "../hooks/useLogout";

export function Navigation() {

    const { isAuthSubscriber } = useAppSelector(state => state.account)
    const location = useLocation()
    const { logoutSubscribe }  = useLogout()
    const onLogout = () => logoutSubscribe()

    let active: boolean = location.pathname === '/' || location.pathname === '/personal-data' || location.pathname === '/pay'
    return (
        <nav className=" bg-white z-10 fixed top-0 w-full h-[50px] md:h-[89px] border-b border-[#F0F4F7] px-4 md:px-[165px] flex justify-between items-center">
            <div className=" w-[70px] md:w-[120px]">
                <Link to="/"><img alt="Logo" src={logo} /></Link>
            </div>  
            <div className=" space-x-8">
                {/*<AppLink to={'/asigurare'} text="Asigurare masina" active={active} />*/}
                <AppLink to={'/faq'} text="FAQ" active={location.pathname === '/faq'} />
                {!isAuthSubscriber && <AppLink to={'/login'} text="Logare" active={location.pathname === '/login'} />}
                
                {isAuthSubscriber && <AppLink to={'/cabinet-personal'} text="Cabinet" active={location.pathname === '/cabinet-personal'} />}
                {isAuthSubscriber && <a className={'text-sm text-black font-semibold hover:text-primeBlue hover:underline'} onClick={onLogout} href="#">Logout</a>}
            </div>
        </nav>
    )
}
