
export default function techIdValidate(value: string): string | null {
    if (value.length !== 9) {
        return 'Format incorect. Pasaportul tehnic trebuie să conțină 9 caractere!';
    }

    let pattern = /^[0-9]{8}[A-Za-z0-9]$/;
    if (!pattern.test(value)) {
        return 'Pasaportul tehnic trebuie să conțină 8 cifre urmate de o literă sau cifră';
    }

    return null;
}

