import React, { useState } from "react"
import Question from "../components/Question"
import { Helmet } from 'react-helmet'
import {Navigation} from "../components/Navigation";
import {Footer} from "../components/Footer";

export function FaqPage() {

    interface IQuestion {
        [key: string]: boolean,
    }

    const questionsInit: IQuestion = {
        showQuestion1: true,
        showQuestion2: false,
        showQuestion3: false,
        showQuestion4: false,
    }

    const [questions, setQuestions] = useState(questionsInit)

    const toggleQuestion = (question: string) => {
        let is = !questions[question]
        setQuestions({...questions, [question]: is })
    }

    return (
        <>
            
        <Helmet>
            <title>Întrebări frecvente despre serviciile noastre de asigurare auto în Republica Moldova</title>
            <meta name="description" content="Află tot ce trebuie să știi despre serviciile noastre de asigurare auto în Republica Moldova prin intermediul paginii noastre FAQ. Găsește răspunsuri la cele mai frecvente întrebări și află cum te putem ajuta să-ți protejezi mașina și investiția. Contactează-ne pentru mai multe detalii." />
        </Helmet>
         <Navigation />   
        <div className=" space-y-3 px-4 grid place-items-center mt-16 w-full md:mt-[140px]">
            {/*<h1 className=" text-black text-2xl md:text-[32px] font-semibold">Intrebari fregvente</h1>*/}
            <p className=" text-black text-base md:text-2xl font-semibold">Intrebări despre <span className=" text-primeBlue">Asigurarea mașinii</span></p>
        </div>
                    
        <div className="px-4 md:px-[165px] mt-6 mb-20 md:mb-28">
            <div className=" grid grid-cols-1 md:grid-cols-2 gap-8">
                
                <Question question="Care sunt tipurile de asigurări auto obligatorii în Republica Moldova?" showContent={questions.showQuestion1} onClick={() => toggleQuestion('showQuestion1')} >
                    <p>În Republica Moldova, singura asigurare auto obligatorie este asigurarea RCA.</p>
                </Question>

                <Question question="Cum se calculează costul unei polițe de asigurare auto în Republica Moldova?" showContent={questions.showQuestion2} onClick={() => toggleQuestion('showQuestion2')} >
                    <p>Costul unei polițe de asigurare auto în Republica Moldova este determinat în funcție de mai mulți factori, precum vârsta șoferului, tipul mașinii, experiența de conducere și istoricul de accidente.</p>
                </Question>

                <Question question="Ce acoperă o poliță de asigurare CASCO și cât de importantă este aceasta în Republica Moldova?" showContent={questions.showQuestion3} onClick={() => toggleQuestion('showQuestion3')} >
                    <p>O poliță de asigurare CASCO poate acoperi daunele produse mașinii dvs. în urma unui accident, a furtului sau a altor evenimente neașteptate, iar aceasta este importantă pentru protejarea investiției dvs. în mașină.</p>
                </Question>

                <Question question="Ce trebuie să fac dacă am fost implicat într-un accident auto în Republica Moldova și am nevoie de asistență din partea companiei mele de asigurare?" showContent={questions.showQuestion4} onClick={() => toggleQuestion('showQuestion4')} >
                    <p>Dacă sunteți implicat într-un accident auto în Republica Moldova, trebuie să notificați imediat compania dvs. de asigurare și să obțineți informații despre cum să depuneți un dosar de despăgubire.</p>
                </Question>
                
            </div>
        </div>
            <Footer />
        </>
                
    )
}
