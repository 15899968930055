import React, { FC } from 'react';

interface PaginationProps {
    totalCount: number;
    perPage: number;
    currentPage: number;
    onPageChange: (page: number) => void;
}

const Pagination: FC<PaginationProps> = ({
                                             totalCount,
                                             perPage,
                                             currentPage,
                                             onPageChange,
                                         }) => {
    const totalPages = Math.ceil(totalCount / perPage);
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    const handlePageChange = (newPage: number) => {
        if (newPage >= 1 && newPage <= totalPages) {
            onPageChange(newPage);
        }
    };

    return (
        <div className="flex justify-center mt-4">
            <nav className="inline-flex rounded-md shadow">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={isFirstPage}
                    className={`${
                        isFirstPage ? 'opacity-50 cursor-default' : 'hover:bg-neutral hover:text-white'
                    } px-3 py-1 rounded-l-md focus:outline-none`}
                >
                    Previous
                </button>
                {[...Array(totalPages)].map((_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={`${
                            currentPage === index + 1
                                ? 'bg-primeBlue text-white'
                                : 'hover:bg-gray hover:text-white'
                        } px-3 py-1 focus:outline-none`}
                    >
                        {index + 1}
                    </button>
                ))}
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={isLastPage}
                    className={`${
                        isLastPage ? 'opacity-50 cursor-default' : 'hover:bg-gray hover:text-white'
                    } px-3 py-1 rounded-r-md focus:outline-none`}
                >
                    Next
                </button>
            </nav>
        </div>
    );
};

export default Pagination;
