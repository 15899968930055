import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface PersonalDatesSlice {
    name: {
        value: string,
        error: string | null
    },
    surname: {
        value: string,
        error: string | null
    },
    phone: {
        value: string,
        error: string | null
    },
    email: {
        value: string,
        error: string | null
    },
    confirmed: { 
        value: boolean,
        error: string | null
    },
    paymentType: 'card' | 'mpay'
}

const initialState: PersonalDatesSlice = {
    name: {
        value: '',
        error: null
    },
    surname: {
        value: '',
        error: null
    },
    phone: {
        value: '',
        error: null
    },
    email: {
        value: '',
        error: null
    },
    confirmed: {
        value: false,
        error: null
    },
    paymentType: 'mpay'
}

export const personalDatesSlice = createSlice({
    name: 'personalDates',
    initialState,
    reducers: {
        setNameValue(state, action: PayloadAction<string>) {
            state.name.value = action.payload
        },
        setNameError(state, action: PayloadAction<string | null>) {
            state.name.error = action.payload
        },
        setSurNameValue(state, action: PayloadAction<string>) {
            state.surname.value = action.payload
        },
        setSureNameError(state, action: PayloadAction<string | null>) {
            state.surname.error = action.payload
        },
        setPhoneValue(state, action: PayloadAction<string>) {
            state.phone.value = action.payload
        },
        setPhoneError(state, action: PayloadAction<string | null>) {
            state.phone.error = action.payload
        },
        setEmailValue(state, action: PayloadAction<string>) {
            state.email.value = action.payload
        },
        setEmailError(state, action: PayloadAction<string | null>) {
            state.email.error = action.payload
        },
        setConfirmed(state, action: PayloadAction<boolean>) {
            state.confirmed.value = action.payload
        },
        setConfirmedError(state, action: PayloadAction<string | null>) {
            state.confirmed.error = action.payload
        },
        setPaymentType(state, action: PayloadAction<'card' | 'mpay'>) {
            state.paymentType = action.payload
        }
    }
})

export const personalDatesActions = personalDatesSlice.actions
export const personalDatesReducer = personalDatesSlice.reducer
