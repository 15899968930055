import {useAuthSubscriberQuery} from '../../store/api/my.api'
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useActions} from "../actions";

const useAuthSubscriber = () => {

    const {
        setIsAuth,
        setPhoneValueAccount,
        setTechIdValueAccount,
        setBaseIdValueAccount,
        setExpirationDateValueAccount,
        setEmailValueAccount,
        setTechRevisionDateValueAccount,
        setCarNumbersValueAccount
    } = useActions()
    
    const {isLoading, isError, data} = useAuthSubscriberQuery('')
    const navigate = useNavigate()
    useEffect(() => {
        if (!isLoading && isError) {
            //clear state
            setIsAuth(false)
            navigate('/login')
        } else if(data) {
            setIsAuth(true)
            setPhoneValueAccount(data.phone.toString())
            setTechIdValueAccount(data.techId)
            setBaseIdValueAccount(data.baseId)
            const expDatesState: Array<Date> = data.expirationDates.map(date => {
                return new Date(date)
            })
            setExpirationDateValueAccount(expDatesState)
            setEmailValueAccount(data.email)
            if (data.techRevisionDates) {
                const techRevisionDatesState : Array<Date> = data.techRevisionDates.map(date => {
                    return new Date(date)
                })
                setTechRevisionDateValueAccount(techRevisionDatesState)
            }
            setCarNumbersValueAccount([''])
            if (data.carNumbers) {
                setCarNumbersValueAccount(data.carNumbers)
            }
            
        }
        
    }, [isLoading, isError, data])
    
}

export default useAuthSubscriber
