
const useFormatDate = () => {
    
    const formatToWeb = (value: Date | null) => {
        if (value) {
            let date = new Date(value)
            let day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
            let month = date.getMonth() + 1
            return day + '.' + (month > 9 ? month : '0' + month) + '.' + date.getFullYear()
        }
        return ''
    }
    
    const formatToServer = (value: Date | null | string) => {
        if (value) {
            let date = new Date(value)
            date.setDate(date.getDate() + 1)
            const [strDate] = date.toISOString().split('T')
            return strDate
        }
        return ''
    }
    
    
    return {formatToWeb, formatToServer}
}

export default useFormatDate 
