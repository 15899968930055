import Label from "../elements/Label";
import Input from "../elements/Input";
import React from "react";
import {useAppSelector} from "../../hooks/redux";
import BasicButton from "../elements/buttons/BasicButton";
import {useUpdateSubscriberFieldMutation} from "../../store/api/my.api";
import {UpdateSubscriberServerResponse} from "../../models/subscribers/models";
import {useActions} from "../../hooks/actions";
import phoneValidate from "../../hooks/validation/phoneValidate";
import {ServerErrorResponse} from "../../models/models";
import useToast from "../../hooks/toast";

export function PhoneModule() {

    const { phone } = useAppSelector(state => state.account)
    const { setPhoneValueAccount, setPhoneErrorAccount } = useActions()

    const onChangePhone = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.length <= 8) {
            let error = phoneValidate(e.currentTarget.value)
            setPhoneValueAccount(e.currentTarget.value)
            setPhoneErrorAccount(error)
        }
    }

    const {toastSuccess, toastError} = useToast()
    
    const [updateSubscriberField, {isLoading}] = useUpdateSubscriberFieldMutation()
    const clickBtn = async () => {
        await updateSubscriberField({
            key: 'phone',
            value: phone.value
        }).unwrap().
        then((payload: UpdateSubscriberServerResponse) => {
            toastSuccess('Success!')
        }).catch((error: ServerErrorResponse) => {
            console.log(error)
            toastError('Eroare!')
        })
    }
    return (
        <div>
            <h1 className=" text-lg pb-10">Numărul Dv de contact: <span className=" text-primeBlue">+373{phone.value}</span></h1>
            
            <Label name="Setați un număr de contact nou" />
            <Input prefix="+373" type="text" name="phone" placeholder="Introduceti nr de contact nou" onChange={onChangePhone} value={phone.value} />
            <span className=" text-error1 text-xs">{phone.error}</span>
            <div className=" w-[50%] py-5">
                <BasicButton disabled={isLoading} label="Actualizează!" onClick={clickBtn} />
            </div>
            
        </div>
    )
}
