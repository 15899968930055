import React from "react";
import BasicButton from "../elements/buttons/BasicButton";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children?: React.ReactNode; 
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children } : ModalProps) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                    onClick={onClose}
                >
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className=" relative p-4 border-[1px] border-primary2 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-[50%]">
                    <span className=" cursor-pointer absolute top-2 right-2 fa fa-close " onClick={onClose}></span>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
