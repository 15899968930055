import {ITableProps} from "../../models/admin/models";
import React, {useMemo} from "react";
import {useTable} from "react-table";
import useFormatDate from "../../hooks/formatDate";
import {IoIosArrowDown} from 'react-icons/io'

const DisplayTable = ({columns, data}: ITableProps) => {

    const dataTable = useMemo(() => {
        if (data) {
            return data
        }
    }, [data])

    let tableInstance = useTable({ columns, data: dataTable });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance

    const {formatToWeb} = useFormatDate()

    const handleSort = (column: string, direction: 'ASC' | 'DESC') => {

    }

    return (
        <>
            <table {...getTableProps() } className="table-auto w-full text-sm">
                    <thead className="bg-gray">
                    {// Loop over the header rows
                        headerGroups.map(headerGroup => (
                            // Apply the header row props
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {// Loop over the headers in each row
                                    headerGroup.headers.map(column =>  {
                                        if (column.Header && column.id) {
                                            return (
                                                // Apply the header cell props
                                                <th {...column.getHeaderProps()}
                                                    className=" px-4 py-2 text-left font-bold text-white">
                                                    <div className="flex flex-col">
                                                        {column.render('Header')}
                                                        <span className={'cursor-pointer'} onClick={() => handleSort('id','ASC')}><IoIosArrowDown /></span>
                                                    </div>
                                                </th>
                                            )
                                        }
                                    })
                                }
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {// Loop over the table rows
                            rows.map(row => {
                                // Prepare the row for display
                                prepareRow(row)
                                return (
                                    // Apply the row props
                                    <tr {...row.getRowProps()} className="bg-white hover:bg-neutral hover:text-white">
                                        {// Loop over the rows cells
                                            row.cells.map(cell => {
                                                // Apply the cell props
                                                //console.log(cell.column.id)
                                                return (
                                                    <td {...cell.getCellProps()} className="px-4 py-2 border-b border-neutral">
                                                        {Array.isArray(cell.value)
                                                            ? cell.value.map((item: any, idx: number) => {
                                                                return <li key={item + idx}>{(['expirationDates', 'techRevisionDates'].includes(cell.column.id)) ? formatToWeb(item) : item}</li>
                                                            })
                                                            : cell.render('Cell')
                                                        }
                                                    </td>
                                                )
                                            })}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
        </>
    );
 }

 export default DisplayTable