import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ISubscriber} from "../../models/admin/models";

export interface SubscriberManageSlice {
    id: number | null
    name: {
        value: string
        error: string | null
    }
    phone: {
        value: string,
        error: null | string
    },
    techId: {
        value: string,
        error: null | string
    },
    baseId: {
        value: string,
        error: null | string
    },
    expirationDates: {
        value: Array<Date>,
        error: null | string
    },
    email: {
        value: string,
        error: null | string
    },
    techRevisionDates: {
        value: Array<Date>,
        error: null | string
    },
    carNumbers: {
        value: Array<string>,
        error: null | string
    },
    openModal: boolean,
    confirmDeleteModal: boolean,
}

const initialState: SubscriberManageSlice = {
    id: null,
    name: {
        value: '',
        error: null
    },
    phone: {
        value: '',
        error: null
    },
    techId: {
        value: '',
        error: null
    },
    baseId: {
        value: '',
        error: null
    },
    email: {
        value: 'fdjfdjlk',
        error: null
    },
    expirationDates: {
        value: [new Date()],
        error: null
    },
    techRevisionDates: {
        value: [new Date()],
        error: null
    },
    carNumbers: {
        value: [''],
        error: null
    },
    openModal: false,
    confirmDeleteModal: false
}

export const subscriberManageSlice = createSlice({
    name: 'subscriber-manage',
    initialState,
    reducers: {
        setOpenModal(state, action: PayloadAction<boolean>) {
            state.openModal = action.payload
        },
        setConfirmDeleteModal(state, action: PayloadAction<boolean>) {
            state.confirmDeleteModal = action.payload
        },
        setIdAdmin(state, action: PayloadAction<number | null>) {
            state.id = action.payload
        },
        setNameValueAdmin(state, action: PayloadAction<string>) {
            state.name.value = action.payload
        },
        setNameErrorAdmin(state, action: PayloadAction<string | null>) {
            state.name.error = action.payload
        },
        setPhoneValueAdmin(state, action: PayloadAction<string>) {
            state.phone.value = action.payload
        },
        setPhoneErrorAdmin(state, action: PayloadAction<string | null>) {
            state.phone.error = action.payload
        },
        setTechIdValueAdmin(state, action: PayloadAction<string>) {
            state.techId.value = action.payload
        },
        setTechIdErrorAdmin(state, action: PayloadAction<string | null>) {
            state.techId.error = action.payload
        },
        setBaseIdValueAdmin(state, action: PayloadAction<string>) {
            state.baseId.value = action.payload
        },
        setBaseIdErrorAdmin(state, action: PayloadAction<string | null>) {
            state.baseId.error = action.payload
        },
        setExpirationDatesValueAdmin(state, action: PayloadAction<Array<Date>>) {
            state.expirationDates.value = action.payload
        },
        updatedExpirationDatesValueAdmin(state, action: PayloadAction<{key: number, value: Date}>) {
            if (state.expirationDates.value)
                state.expirationDates.value[action.payload.key] = action.payload.value
        },
        setExpirationDatesErrorAdmin(state, action: PayloadAction<string | null>) {
            state.expirationDates.error = action.payload
        },
        setEmailValueAdmin(state, action: PayloadAction<string>) {
            state.email.value = action.payload
        },
        setEmailErrorAdmin(state, action: PayloadAction<string | null>) {
            state.email.error = action.payload
        },
        setTechRevisionDatesValueAdmin(state, action: PayloadAction<Array<Date>>) {
            state.techRevisionDates.value = action.payload
        },
        updatedTechRevisionDateValueAdmin(state, action: PayloadAction<{key: number, value: Date}>) {
            if (state.techRevisionDates.value)
                state.techRevisionDates.value[action.payload.key] = action.payload.value
        },
        setTechRevisionDatesErrorAdmin(state, action: PayloadAction<string | null>) {
            state.techRevisionDates.error = action.payload
        },
        setCarNumbersAdmin(state, action: PayloadAction<Array<string>>) {
            state.carNumbers.value = action.payload
        },
        updatedCarNumberValueAdmin(state, action: PayloadAction<{key: number, value: string}>) {
            if (state.carNumbers.value)
                state.carNumbers.value[action.payload.key] = action.payload.value
        },
        setCarNumbersErrorAdmin(state, action: PayloadAction<string | null>) {
            state.carNumbers.error = action.payload
        }
    }
})

export const subscriberManageActions = subscriberManageSlice.actions
export const subscriberManageReducer = subscriberManageSlice.reducer

