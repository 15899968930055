import React, { FC } from 'react';

interface PerPageRadioButtonsProps {
    options: number[];
    selectedOption: number;
    onOptionChange: (option: number) => void;
}

const PerPageRadioButtons: FC<PerPageRadioButtonsProps> = ({
                                                               options,
                                                               selectedOption,
                                                               onOptionChange,
                                                           }) => {
    return (
        <div className="flex items-center mb-4">
            <span className="mr-2 text-sm">Itemi pe pagina:</span>
            {options.map((option) => (
                <label key={option} className="inline-flex items-center mr-4">
                    <input
                        type="radio"
                        value={option}
                        checked={selectedOption === option}
                        onChange={() => onOptionChange(option)}
                        className="form-radio h-4 w-4 text-blue-600"
                    />
                    <span className="ml-1">{option}</span>
                </label>
            ))}
        </div>
    );
};

export default PerPageRadioButtons;
