import Label from "../elements/Label";
import Input from "../elements/Input";
import React from "react";
import {useAppSelector} from "../../hooks/redux";
import BasicButton from "../elements/buttons/BasicButton";
import {useUpdateSubscriberFieldMutation} from "../../store/api/my.api";
import {UpdateSubscriberServerResponse} from "../../models/subscribers/models";
import emailValidate from "../../hooks/validation/emailValidate";
import {useActions} from "../../hooks/actions";
import {ServerErrorResponse} from "../../models/models";
import useToast from "../../hooks/toast";


export function EmailModule() {

    const { email } = useAppSelector(state => state.account)
    const { setEmailValueAccount, setEmailErrorAccount} = useActions()

    const onChangeEmail = (e: React.FormEvent<HTMLInputElement>) => {
        setEmailValueAccount(e.currentTarget.value)
        setEmailErrorAccount(null)
        if (e.currentTarget.value) {
            let error = emailValidate(e.currentTarget.value)
            setEmailErrorAccount(error)
        }
    }

    const [updateSubscriberField, {isLoading}] = useUpdateSubscriberFieldMutation()

    const {toastSuccess, toastError} = useToast()
    
    const clickBtn = async () => {
        await updateSubscriberField({
            key: 'email',
            value: email.value
        }).unwrap().
        then((payload: UpdateSubscriberServerResponse) => {
            toastSuccess('Success!')
        }).catch((error: ServerErrorResponse) => {
            toastError('Error!')
        })
    }
    
    return (
        <div>
            <h1 className=" text-lg pb-10">Adresa de email: <span className=" text-primeBlue">{email.value}</span></h1>

            <Label name="Adresă nouă" />
            <Input type="text" name="email" placeholder="Introduceti adresă de email noua" onChange={onChangeEmail} value={email.value} />
            <span className=" text-error1 text-xs">{email.error}</span>

            <div className=" w-[50%] py-5">
                <BasicButton disabled={isLoading} label="Actualizează!" onClick={clickBtn} />
            </div>
        </div>
    )
}
