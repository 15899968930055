import { myApi } from './api/my.api';
import { adminApi } from './api/admin.api';
import { configureStore, getDefaultMiddleware, combineReducers } from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/query";
import {homeReducer} from "./home/home.slice";
import {personalDatesReducer} from "./dates/personalDates.slice";
import {subscribeReducer} from "./subscribe/subscribe.slice";
import {accountReducer} from "./account/account.slice";
import {subscriberManageReducer} from "./admin/subscriber-manage.slice"
import { managerReducer } from "./admin/manager.slice";

import { persistStore, persistReducer, PersistConfig } from 'redux-persist'
import storage from 'redux-persist/lib/storage';

const persistConfig: PersistConfig<any> = {
    key: 'root',
    storage,
    blacklist: [myApi.reducerPath, adminApi.reducerPath, 'subscriberManage']
    //blacklist: [myApi.reducerPath]
}

const rootReducer = combineReducers({
    [myApi.reducerPath]: myApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    home: homeReducer,
    subscribe: subscribeReducer,
    personalDates: personalDatesReducer,
    account: accountReducer,
    subscriberManage: subscriberManageReducer,
    manager: managerReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({  
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({serializableCheck: false}).concat(myApi.middleware)
})

setupListeners(store.dispatch)

export const persistEr = persistStore(store)

export type RootState = ReturnType<typeof store.getState>

export default store
