import {ISubscriber, ISubscribersAdminServerResponse} from '../../models/models';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
    DeleteSubscriberResponse,
    ILoginAdminData,
    ILoginAdminServerResponse,
    ISaveSubscriberPayload,
    ISaveSubscriberServerResponse, RestoreSubscriberResponse, SaveSubscriberSResponse
} from "../../models/admin/models";

export const adminApi = createApi({
    reducerPath: 'api/admin',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:6080/' : 'https://api.365asig.md/',
        prepareHeaders: (headers) => {
            const token = localStorage.getItem('token') ? JSON.parse(<string>localStorage.getItem('token')) : ''
            headers.set('Content-Type', 'application/json')
            headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    refetchOnFocus: true,
    tagTypes: ['Data'],
    endpoints: build => ({
        
        authManager: build.query({
            query: () => ({
                url: `auth/manager`,
            })
        }),

        authAdmin: build.query({
            query: () => ({
                url: `auth/admin`,
            })
        }),
        
        loginAdmin: build.mutation<ILoginAdminServerResponse, ILoginAdminData>({
            query:(payload: ILoginAdminData) => ({
                url: `admin/login`,
                method: 'POST',
                body: payload
            })
        }),

        getAdminSubscribers: build.query<ISubscribersAdminServerResponse, { search: string, page: number, perPage: number, trash: boolean }>({
            query: (args) => ({
                url: `admin/subscribers`,
                params: {
                    search: args.search,
                    per_page: args.perPage,
                    page: args.page,
                    trash: args.trash
                }
            }),
            // transformResponse: (response: ServerResponse<ISubscriber>) => response.items
        }),
        
        saveSubscriber: build.mutation<SaveSubscriberSResponse, ISaveSubscriberPayload>({
            query: (payload: ISaveSubscriberPayload)=> ({
                url: `admin/subscriber/save`,
                method: 'POST',
                body: payload
            }),
        }),

        deleteSubscriber: build.mutation<DeleteSubscriberResponse, {id: number, permanent: boolean}>({
            query: (args)=> ({
                url: `admin/subscriber/delete/${args.id}/permanent/${args.permanent}`,
                method: 'DELETE'
            })
        }),

        restoreSubscriber: build.mutation<RestoreSubscriberResponse, number>({
            query: (id: number)=> ({
                url: `admin/subscriber/restore/${id}`,
                method: 'POST'
            })
        }),

    })
})

export const {
    useAuthAdminQuery,
    useAuthManagerQuery,
    useLoginAdminMutation,
    useGetAdminSubscribersQuery,
    useSaveSubscriberMutation,
    useDeleteSubscriberMutation,
    useRestoreSubscriberMutation
} = adminApi


