
export default function passcodeValidate(value: string): string | null {
    if (value.length < 1) {
        return 'Introduceti numarul din 6 cifre'
    }
    if (value.length !== 6) {
        return 'Format incorect! Codul contine 6 cifre'
    }
    let pattern = /^\d+\.?\d*$/;
    if (!pattern.test(value)) {
        return 'Codul trebuie sa contina doar numere'
    }

    return null
}
