import Label from "../elements/Label";
import Input from "../elements/Input";
import React from "react";
import {useAppSelector} from "../../hooks/redux";
import {useUpdateSubscriberFieldMutation} from "../../store/api/my.api";
import {UpdateSubscriberServerResponse} from "../../models/subscribers/models";
import BasicButton from "../elements/buttons/BasicButton";
import {useActions} from "../../hooks/actions";
import techIdValidate from "../../hooks/validation/techIdValidate";
import {ServerErrorResponse} from "../../models/models";
import useToast from "../../hooks/toast";


export function TechIdModule() {

    const { techId } = useAppSelector(state => state.account)
    const { setTechIdValueAccount, setTechIdErrorAccount} = useActions()

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.length <= 9) {
            let error = techIdValidate(e.currentTarget.value)
            setTechIdValueAccount(e.currentTarget.value)
            setTechIdErrorAccount(error)
        }
    }

    const {toastSuccess, toastError} = useToast()
    const [updateSubscriberField, {isLoading}] = useUpdateSubscriberFieldMutation()
    const clickBtn = async () => {
        await updateSubscriberField({
            key: 'tech_id',
            value: techId.value
        }).unwrap().
        then((payload: UpdateSubscriberServerResponse) => {
            toastSuccess('Success!')
        }).catch((error: ServerErrorResponse) => {
            toastError('Server error')
        })
    }
    
    return (
        <div>
            <h1 className=" text-lg pb-10">Pasaport tehnic nr: <span className=" text-primeBlue">{techId.value}</span></h1>

            <Label name="Pasaport tehnic nou:" />
            <Input type="text" name="techId" placeholder="Introduceti pasaport tehnic" onChange={onChange} value={techId.value} />
            <span className=" text-error1 text-xs">{techId.error}</span>

            <div className=" w-[50%] py-5">
                <BasicButton disabled={isLoading} label="Actualizează!" onClick={clickBtn} />
            </div>
        </div>
    )
}
