import React, {useEffect, useState} from "react";
import Label from "../components/elements/Label";
import Input from "../components/elements/Input";
import {useAppSelector} from "../hooks/redux";
import {useActions} from "../hooks/actions";
import phoneValidate from "../hooks/validation/phoneValidate";
import techIdValidate from "../hooks/validation/techIdValidate";
import baseIdValidate from "../hooks/validation/baseIdValidate";
import emailValidate from "../hooks/validation/emailValidate";
import BasicButton from "../components/elements/buttons/BasicButton";
import DatePicker, {registerLocale} from "react-datepicker"
import ro from "date-fns/locale/ro"

import "../assets/custom-react-datepicker.css";
import TechId from "../assets/TechId";
import idnpImage from "../assets/idnp.jpeg"
import policyImg from "../assets/insurance.png"
import techReportImg from "../assets/raport-verificarea-tehnica.gif"
import car from '../assets/Car365asig.png'
import ShowMoreLessButton from "../components/elements/buttons/ShowMoreLessButton";
import useShowMoreLess from "../hooks/showMoreLess";
import useIsMobile from "../hooks/isMobile";
import Checkbox from "../components/elements/Checkbox";
import {CreateSubscriberErrorResponse, CreateSubscriberServerResponse} from "../models/subscribers/models";
import {useCreateSubscriberMutation} from "../store/api/my.api";
import {useNavigate} from "react-router-dom";
import ReCAPTCHA  from "react-google-recaptcha";
import useLocalStorage from "../hooks/localStorage";
import useToast from "../hooks/toast";
import useFormatDate from "../hooks/formatDate";

import { Helmet } from 'react-helmet'
import useModal from "../hooks/Modal";
import {Navigation} from "../components/Navigation";
import {Footer} from "../components/Footer";

export function SubscribePage() {
    
    registerLocale("ro", ro)

    const navigate = useNavigate()
    
    const {isAuthSubscriber} = useAppSelector(state => state.account)
    useEffect(() => {
        if (isAuthSubscriber) {
            navigate('/cabinet-personal')
        }
    }, [isAuthSubscriber])
    
    const {
        phone,
        techId,
        baseId,
        expirationDates,
        email,
        techRevisionDates,
        carNumbers,
        checkData,
        acceptTerms
    } = useAppSelector(state => state.subscribe)
    
    
    const {
        setPhoneValueS,
        setPhoneErrorS,
        setTechIdValueS,
        setTechIdErrorS,
        setBaseIdValueS,
        setBaseIdErrorS,
        setExpirationDateValueS,
        setEmailValueS,
        setEmailErrorS,
        setTechRevisionDateValueS,
        setCarNumbersValueS,
        setCarNumbersErrorS,
        setCheckDataValueS,
        setCheckDataErrorS,
        setAcceptTermsValueS,
        setAcceptTermsErrorS
    } = useActions()
    
    const onChangePhone = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.length <= 8) {
            let error = phoneValidate(e.currentTarget.value)
            setPhoneValueS(e.currentTarget.value)
            setPhoneErrorS(error)
        }
    }

    const onChangeTechId = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.length <= 9) {
            let error = techIdValidate(e.currentTarget.value)
            setTechIdValueS(e.currentTarget.value)
            setTechIdErrorS(error)
        }
    }

    const onChangeBaseId = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.length <= 13) {
            let error = baseIdValidate(e.currentTarget.value)
            setBaseIdValueS(e.currentTarget.value)
            setBaseIdErrorS(error)
        }
    }
    
    const onChangeExpirationDate = (date: Date) => {
        setExpirationDateValueS([new Date(date)])
    }

    const onChangeEmail = (e: React.FormEvent<HTMLInputElement>) => {
        setEmailValueS(e.currentTarget.value)
        setEmailErrorS(null)
        if (e.currentTarget.value) {
            let error = emailValidate(e.currentTarget.value)
            setEmailErrorS(error)
        }
        
    }
    
    const onChangeRevisionExpirationDate = (date: Date) => {
        setTechRevisionDateValueS([new Date(date)])
    }

    const onChangeCarNumber = (e: React.FormEvent<HTMLInputElement>) => {
        setCarNumbersErrorS(null)
        if (e.currentTarget.value.length <= 9) {
            setCarNumbersValueS([e.currentTarget.value])
        }
        if (e.currentTarget.value.length < 6) {
            setCarNumbersErrorS('Minim 6 simboluri')
        }

    }

    const onSetCheckData = (e: React.FormEvent<HTMLInputElement>) => {
        setCheckDataValueS(e.currentTarget.checked)
        setCheckDataErrorS(e.currentTarget.checked ? null : 'Confirmati verificarea datelor!')
    }
    
    const onAcceptTerms = (e: React.FormEvent<HTMLInputElement>) => {
        setAcceptTermsValueS(e.currentTarget.checked)
        setAcceptTermsErrorS(e.currentTarget.checked ? null : 'Ne pare rău, dar trebuie să bifați caseta pentru a confirma că sunteți de acord cu termenii și condițiile noastre!')
    }

    const [captchaError, setCaptchaError] = useState<string | null>(null)
    const [captchaVal, setCaptchaVal] = useState<string | null>(null)
    const captchaChange = (value: string | null) => {
        setCaptchaVal(value)
        setCaptchaError(null)
    }
    
    const {setItem} = useLocalStorage()
    const [createSubscriber, {isLoading}] = useCreateSubscriberMutation()
    const {toastError, toastSuccess} = useToast()
    const {formatToServer} = useFormatDate()
    
    const clickBtn = async () => {
        let errorPhone = phoneValidate(phone.value)
        let errorBaseId = baseIdValidate(baseId.value)
        let errorTechId = techIdValidate(techId.value)
        let errorEmail = emailValidate(email.value)
        if (errorPhone || errorBaseId || errorTechId || errorEmail || !checkData.value || !acceptTerms.value || email.error || carNumbers.error || !captchaVal) {
            setPhoneErrorS(errorPhone)
            setBaseIdErrorS(errorBaseId)
            setTechIdErrorS(errorTechId)
            setEmailErrorS(errorEmail)
            if (!checkData.value) {
                setCheckDataErrorS('Confirmati verificarea datelor!')
            }
            if (!captchaVal) {
                setCaptchaError('Bifati captcha!')
            }
            if (!acceptTerms.value) {
                setAcceptTermsErrorS('Ne pare rău, dar trebuie să bifați caseta pentru a confirma că sunteți de acord cu termenii și condițiile noastre!')
            }
            return
        }
        
        await createSubscriber({
            phone : parseInt(phone.value),
            id_np: baseId.value,
            tech_id: techId.value,
            expiration_dates: expirationDates.value,
            ...(email.value && { email: email.value }),
            tech_revision_dates: techRevisionDates.value,
            car_numbers: carNumbers.value
        }).unwrap()
            .then((payload: CreateSubscriberServerResponse) => {
                setPhoneValueS('')
                setBaseIdValueS('')
                setTechIdValueS('')
                setExpirationDateValueS([new Date()])
                setEmailValueS('')
                setTechRevisionDateValueS(null)
                setItem('token', payload.token)
                navigate('/success-subscribe')
                toastSuccess('Success!')
            })
            .catch((error: CreateSubscriberErrorResponse ) => {
                toastError(error.data.message)
            })
        
    }

    const {showMoreFlag, rotate , handleShowLess} = useShowMoreLess()
    const isMobile = useIsMobile()
    
    const [imgInfo, setImgInfo] = useState<'car' | 'techId' | 'idnp' | 'asig_date' | 'techId_date'>('car')
    
    const {setShowModal, renderModal} = useModal(false)
    
    return (
        <>
        <Helmet>
            <title>Siguranța ta la volan - Servicii de asigurare auto în Republica Moldova</title>
            <meta name="description" content="Protejează-ți mașina și investiția cu serviciile noastre de asigurare auto în Republica Moldova. Alege dintr-o gamă variată de opțiuni de acoperire și beneficiază de un partener de încredere în situații neprevăzute. Contactează-ne acum pentru o ofertă personalizată." />
         </Helmet>
            <Navigation/>
            {renderModal}
            <div className=" mt-10 px-4 md:px-[165px] md:mt-[80px] mb-[100px] md:mb-[150px] space-y-3">
            <div className=" flex flex-col-reverse md:flex-row md:h-auto pb-5">
                <div className=" space-y-6 py-[30px] md:px-8 md:shadow-1 md:w-[45%] md:mr-8">
                    <div className=" space-y-2">
                        <Label name="Nr. de telefon" required />
                        <Input prefix="+373" type="text" name="phone" placeholder="Introduceti nr de telefon" onChange={onChangePhone} value={phone.value} />
                        <span className=" text-error1 text-xs">{phone.error}</span>
                    </div>
                    <div className=" space-y-2">
                        <Label name="Id pasaport tehnic" required />
                        <Input type="text" name="techId" placeholder="Introduceti nr pasaport tehnic" onFocus={() => setImgInfo('techId')} onChange={onChangeTechId} value={techId.value} />
                        <span className=" text-error1 text-xs">{techId.error}</span>
                    </div>
                    <div className=" space-y-2">
                        <Label name="IDNO" required />
                        <Input type="text" name="baseId" placeholder="Introduceti IDNO" onFocus={() => setImgInfo('idnp')} onChange={onChangeBaseId} value={baseId.value} />
                        <span className=" text-error1 text-xs">{baseId.error}</span>
                    </div>
                    <div className=" space-y-2">
                        <Label name="Data expirarii asigurarii actuale" required />
                        <DatePicker
                            onFocus={() => setImgInfo('asig_date')}
                            dateFormat="dd.MM.yyyy"
                            selected={expirationDates.value[0]}
                            locale="ro"
                            onChange={(date:Date) => onChangeExpirationDate(date)}
                            className=" w-full h-[48px] bg-white p-4 border-[1.5px] border-primaryBlbg rounded-[10px] text-sm placeholder-gray cursor-pointer hover:border-primary2 focus:border-primeBlue focus:text-black"
                        />
                        <span className=" text-error1 text-xs">{expirationDates.error}</span>
                    </div>
                    <div className=" space-y-2">
                        <Label name="Email" required />
                        <Input type="text" name="email" placeholder="Introduceti email" onChange={onChangeEmail} value={email.value} />
                        <span className=" text-error1 text-xs">{email.error}</span>
                    </div>
                    <div className=" space-y-2">
                        <Label name="Data expirarii revizie tehnica" />
                        <DatePicker
                            onFocus={() => setImgInfo('techId_date')}
                            placeholderText={'Data expirarii'}
                            dateFormat="dd.MM.yyyy"
                            selected={techRevisionDates.value ? new Date(techRevisionDates.value[0]) : null}
                            locale="ro"
                            onChange={(date:Date) => onChangeRevisionExpirationDate(date)}
                            className=" w-full h-[48px] bg-white p-4 border-[1.5px] border-primaryBlbg rounded-[10px] text-sm placeholder-gray cursor-pointer hover:border-primary2 focus:border-primeBlue focus:text-black"
                        />
                        <span className=" text-error1 text-xs">{techRevisionDates.error}</span>
                    </div>
                    <div className={' space-y-2'}>
                        <Label name={'Numărul mașinii'} />
                        <Input type={'text'} name={'car_numbers'} placeholder={'Introduceți numărul mașinii'} value={carNumbers.value ? carNumbers.value[0] : ''} onChange={onChangeCarNumber} />
                        <span className=" text-error1 text-xs">{carNumbers.error}</span>
                    </div>
                    <div className=" pt-6 space-y-2">
                        <Checkbox id="checkAccept" name="checkAccept" checked={checkData.value} onChange={onSetCheckData} value="accept" label="Am verificat corectitudinea datelor!" />
                        <span className=" text-error1 text-xs">{checkData.error}</span>
                    </div>
                    <div className=" ">
                        <Checkbox id="termsAndConditions" name="termsAndConditions" checked={acceptTerms.value} onChange={onAcceptTerms} value="accept" label="Vă rugăm să bifați căsuța de mai jos pentru a confirma că sunteți de acord cu termenii și condițiile noastre privind protecția datelor cu caracter personal și că sunteți de acord cu stocarea acestora pe site-ul nostru web" />
                        <div className={'text-center'}>
                            <button onClick={() => setShowModal(true)} className={' text-xs text-primary2 hover:underline'}>Termeni si conditii</button>
                        </div>
                        <span className=" text-error1 text-xs">{acceptTerms.error}</span>
                    </div>
                    <div>
                        <ReCAPTCHA onChange={captchaChange} sitekey={'6LeEYwgjAAAAAKbm6jgc3jYODYn5pYOzNxOJXp3-'}/>
                        <span className=" text-error1 text-xs">{captchaError}</span>
                    </div>
                    <div>
                        <BasicButton disabled={isLoading} label="Înregistrează-te!" onClick={clickBtn} />
                    </div>
                </div>
                {/* Left side end */}

                {/* Right side begin */}
                <div className=" pt-[30px] border-b border-primaryBlbg2 md:border-none md:px-8 md:shadow-1 md:w-[55%] ">
                    {/* content block begin */}
                    <div className=" space-y-3">
                        <div className={ `space-y-3` + (!showMoreFlag && isMobile ? ` h-[150px] overflow-hidden opacity-30` : ``)}>
                            <h3 className=" text-black text-base font-semibold">Protejați-vă mașina cu serviciile noastre de asigurare auto accesibile</h3>

                            <p className=" text-ellipsis overflow-hidden text-sm text-neutral font-normal">
                                Indiferent de cât de sigur conduceți, accidentele pot apărea oricând. Este important să fiți pregătit cu asigurarea potrivită pentru mașina dvs. În caz de accident sau daune, o poliță de asigurare vă poate proteja de costuri financiare mari. Serviciile noastre de asigurare auto vă pot ajuta să alegeți cea mai bună opțiune de asigurare pentru nevoile dvs. Indiferent dacă căutați asigurare de răspundere civilă auto sau o poliță completă care să acopere și daunele suferite de vehiculul dvs., suntem aici pentru a vă ajuta să obțineți protecția de care aveți nevoie la un preț accesibil.
                            </p>
                            <p className=" text-ellipsis overflow-hidden text-sm text-neutral font-normal pb-5">
                                Introduceți datele dvs. sau contactați-ne și noi ne vom asigura că primiți asigurarea dorită atât în format electronic, cât și fizic. De asemenea, vom avea grijă să fiți informați la timp cu privire la data expirării asigurării dvs.
                            </p>

                            {imgInfo === 'car' && <img src={car}  alt='car' />}
                            {imgInfo === 'techId' && <TechId />}
                            {imgInfo === 'idnp' && <img src={idnpImage} alt='idnp' />}
                            {imgInfo === 'asig_date' && <img src={policyImg} alt='asig date' />}
                            {imgInfo === 'techId_date' && <img src={techReportImg} alt='tech id date' />}
                            {/*<MyLogo />*/}
                        </div>

                        {isMobile && <div className="">
                            <div className=" grid place-items-center">
                                <ShowMoreLessButton rotate={rotate} label={showMoreFlag === true ? 'Show less' : 'Show more'} onClick={handleShowLess} />
                            </div>
                        </div>}
                    </div>
                    {/* content block end */}
                </div>
                {/* Right side end */}
            </div>
        </div>
            <Footer />
        </>
    )
}
