import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface SubscribeSlice {
    token: string,
    phone: {
        value: string,
        error: null | string
    },
    techId: {
        value: string,
        error: null | string
    },
    baseId: {
        value: string,
        error: null | string
    },
    expirationDates: {
        value: Date[],
        error: null | string
    },
    email: {
        value: string,
        error: null | string
    },
    techRevisionDates: {
        value: null | Date[],
        error: null | string
    },
    carNumbers: {
        value: null | string[],
        error: null | string
    },
    checkData: {
        value: boolean,
        error: null | string
    },
    acceptTerms: {
        value: boolean,
        error: null | string
    },
}

const initialState: SubscribeSlice = {
    token : '',
    phone: {
        value: '',
        error: null
    },
    techId: {
        value: '',
        error: null
    },
    baseId: {
        value: '',
        error: null
    },
    expirationDates: {
        value: [new Date()],
        error: null
    },
    email: {
        value: '',
        error: null
    },
    techRevisionDates: {
        value: null,
        error: null
    },
    carNumbers: {
        value: null,
        error: null
    },
    checkData: {
        value: false,
        error: null
    },
    acceptTerms: {
        value: false,
        error: null
    },
}

export const subscribeSlice = createSlice({
    name: 'subscribe',
    initialState,
    reducers: {
        setToken(state, action: PayloadAction<string>) {
          state.token = action.payload  
        },
        setPhoneValueS(state, action: PayloadAction<string>) {
            state.phone.value = action.payload
        },
        setPhoneErrorS(state, action: PayloadAction<string | null>) {
            state.phone.error = action.payload
        },
        setTechIdValueS(state, action: PayloadAction<string>) {
            state.techId.value = action.payload
        },
        setTechIdErrorS(state, action: PayloadAction<string | null>) {
            state.techId.error = action.payload
        },
        setBaseIdValueS(state, action: PayloadAction<string>) {
            state.baseId.value = action.payload
        },
        setBaseIdErrorS(state, action: PayloadAction<string | null>) {
            state.baseId.error = action.payload
        },
        setExpirationDateValueS(state, action: PayloadAction<Date[]>) {
            state.expirationDates.value = action.payload
        },
        setExpirationDateErrorS(state, action: PayloadAction<string | null>) {
            state.expirationDates.error = action.payload
        },
        setEmailValueS(state, action: PayloadAction<string>) {
            state.email.value = action.payload
        },
        setEmailErrorS(state, action: PayloadAction<string | null>) {
            state.email.error = action.payload
        },
        setTechRevisionDateValueS(state, action: PayloadAction<null | Date[]>) {
            state.techRevisionDates.value = action.payload
        },
        setTechRevisionDateErrorS(state, action: PayloadAction<string | null>) {
            state.techRevisionDates.error = action.payload
        },
        setCarNumbersValueS(state, action: PayloadAction<null | string[]>) {
            state.carNumbers.value = action.payload
        },
        setCarNumbersErrorS(state, action: PayloadAction<null | string>) {
            state.carNumbers.error = action.payload
        },
        setCheckDataValueS(state, action: PayloadAction<boolean>) {
            state.checkData.value = action.payload
        },
        setCheckDataErrorS(state, action: PayloadAction<null | string>) {
            state.checkData.error = action.payload
        },
        setAcceptTermsValueS(state, action: PayloadAction<boolean>) {
            state.acceptTerms.value = action.payload
        },
        setAcceptTermsErrorS(state, action: PayloadAction<null | string>) {
            state.acceptTerms.error = action.payload
        },
    }
})

export const subscribeActions = subscribeSlice.actions
export const subscribeReducer = subscribeSlice.reducer

