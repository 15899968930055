import React from "react";
import { useNavigate } from "react-router-dom";
import SuccessImg from "../assets/SuccessImg";
import BasicButton from "../components/elements/buttons/BasicButton";
import SuccessChecked from "../components/icons/SuccessChecked";
import {Navigation} from "../components/Navigation";
import {Footer} from "../components/Footer";
import useAuthSubscriber from "../hooks/auth/useAuthSubscriber";

export const SuccessSubscribePage = () => {
    const navigate = useNavigate()
    useAuthSubscriber()
    return (
        <>
        <Navigation />
        <div className=" space-y-3 px-4 grid place-items-center mt-16 w-full md:mt-[140px] mb-[100px] md:mb-[150px]">
            <div className=" flex justify-between items-center">
                <h2 className=" text-black text-lg md:text-2xl font-semibold">Ati inregistrat datele cu success!</h2> <SuccessChecked />
            </div>
            
            <div className=" w-[181px] pt-6">
                <BasicButton label="Acasa" onClick={()=> navigate('/')} />
            </div>
            <div className=" w-full md:w-[600px] pt-6">
                <SuccessImg />
            </div>
        </div>
        <Footer />
        </>
    )
}
