import { IQuestionProps } from '../models/models'

const Question = ({question, showContent, onClick, children}: IQuestionProps) => {
    return (
        <div>
            <div onClick={onClick} className={` rounded-[10px] ${showContent ? 'rounded-b-none' : ''} cursor-pointer relative h-auto bg-primaryBlbg flex items-center px-4 py-4`}>
                <span className=" text-black font-semibold text-base">{question}</span>
                <span className=" absolute right-2 top-5 flex items-center pr-2 pointer-events-none">
                    <svg transform={`rotate(${showContent ? '180' : '0'})`} className=" stroke-[#6591B1] stroke-2 hover:stroke-[#66ADCE]" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 1.5L4.5 4.5L1 1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            </div>
            <div className={` ${showContent ? 'block' : 'hidden'} border-[1.5px] border-primaryBlbg rounded-b-[10px] p-4 text-black text-sm`}>
                {children}
            </div>
        </div>
    )
}

export default Question
