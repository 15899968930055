import {useAuthManagerQuery} from '../../store/api/admin.api'
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useActions} from "../actions";

const useAuthManager = () => {
    const {isError, isLoading, data} = useAuthManagerQuery('')
    const {setManagerAdmin} = useActions()
    const navigate = useNavigate()
    useEffect(() => {
        if (!isLoading && isError) {
            navigate('/admin')
        }
        if (!isError && data) {
            setManagerAdmin(data)
        }
    }, [isError, isLoading, data])
}

export default useAuthManager
