import React, {useEffect, useState} from "react";
import {PhoneModule} from "../components/myaccount/PhoneModule";
import {EmailModule} from "../components/myaccount/EmailModule";
import {ExpirationDateModule} from "../components/myaccount/ExpirationDateModule";
import {IdnpModule} from "../components/myaccount/IdnpModule";
import {TechIdModule} from "../components/myaccount/TechIdModule";
import {TechRevisionDateModule} from "../components/myaccount/TechRevisionDateModule";
import { CarNumberModule } from "../components/myaccount/CarNumberModule";
import {registerLocale} from "react-datepicker";
import ro from "date-fns/locale/ro";
import car from '../assets/Car365asig.png'
import {Navigation} from "../components/Navigation";
import {Footer} from "../components/Footer";
import useAuthSubscriber from "../hooks/auth/useAuthSubscriber";


export function MyAccount() {
    registerLocale("ro", ro)
    
    useAuthSubscriber()
    
    const components: {[index: string]: any} = {
        phone: PhoneModule,
        email: EmailModule,
        expirationDate: ExpirationDateModule,
        baseId: IdnpModule,
        techId: TechIdModule,
        techRevisionDate: TechRevisionDateModule,
        carNumber: CarNumberModule
    };
    
    const [module, setModule] = useState('phone')
    const MyComponent = components[module]
    
    return (
        <>
        <Navigation/>
        <div className=" mt-10 px-4 md:px-[165px] md:mt-[130px] mb-[100px] md:mb-[150px] space-y-3">
            <div className=" flex flex-col md:flex-row md:h-[850px]">
                <div className=" space-y-6 pt-[30px] md:px-8 md:shadow-1 md:w-[45%] md:mr-8">
                    <h1 className=" font-semibold text-lg text-center">Setări</h1>
                    <div className=" space-y-2">
                        <button onClick={() => setModule('phone')} className={ `text-sm font-semibold hover:text-primeBlue ${module === 'phone' ? 'text-primeBlue' : ''}`}>Număr de contact</button>
                    </div>
                    <div className=" space-y-2">
                        <button onClick={() => setModule('email')} className={` text-sm font-semibold hover:text-primeBlue ${module === 'email' ? 'text-primeBlue' : ''}`}>Email</button>
                    </div>
                    <div className=" space-y-2">
                        <button onClick={() => setModule('expirationDate')} className={`text-sm font-semibold hover:text-primeBlue ${module === 'expirationDate' ? 'text-primeBlue' : ''}`}>Data expirării asigurare</button>
                    </div>
                    <div className=" space-y-2">
                        <button onClick={() => setModule('baseId')} className={`text-sm font-semibold hover:text-primeBlue ${module === 'baseId' ? 'text-primeBlue' : ''}`}>IDNO</button>
                    </div>
                    <div className=" space-y-2">
                        <button onClick={() => setModule('techId')} className={`text-sm font-semibold hover:text-primeBlue ${module === 'techId' ? 'text-primeBlue' : ''}`}>Pașaport tehnic</button>
                    </div>
                    <div className=" space-y-2">
                        <button onClick={() => setModule('techRevisionDate')} className={`text-sm font-semibold hover:text-primeBlue ${module === 'techRevisionDate' ? 'text-primeBlue' : ''}`}>Data expirării reviziei tehnice</button>
                    </div>
                    <div className=" space-y-2">
                        <button onClick={() => setModule('carNumber')} className={`text-sm font-semibold hover:text-primeBlue ${module === 'carNumber' ? 'text-primeBlue' : ''}`}>Numarul masinii</button>
                    </div>
                </div>
                {/* Left side end */}

                {/* Right side begin */}
                <div className=" pt-[30px] border-b border-primaryBlbg2 md:border-none md:px-8 md:shadow-1 md:w-[55%] ">
                    {/* content block begin */}
                    <div className=" space-y-3">
                        <MyComponent />
                        <img src={car}/>
                    </div>
                    {/* content block end */}
                </div>
                {/* Right side end */}
            </div>
        </div>
            <Footer />
        </>
    )
}
