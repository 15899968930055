import { useState, useEffect } from 'react';

interface TimerProps {
    time: number;
}

const useTimer = ({ time }: TimerProps) => {
    const [seconds, setSeconds] = useState(time);

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (seconds > 0) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [seconds]);
    
    const reset = (resetTime: TimerProps) => {
        setSeconds(resetTime.time)
    }

    return {seconds, reset};
};

export default useTimer;
