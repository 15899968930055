import {useDispatch} from "react-redux";
import {bindActionCreators} from "@reduxjs/toolkit";
import {homeActions} from "../store/home/home.slice";
import {personalDatesActions} from "../store/dates/personalDates.slice";
import {subscribeActions} from "../store/subscribe/subscribe.slice";
import {accountActions} from "../store/account/account.slice";
import {subscriberManageActions} from "../store/admin/subscriber-manage.slice";
import { managerActions} from "../store/admin/manager.slice";

const actions = {
    ...homeActions,
    ...personalDatesActions,
    ...subscribeActions,
    ...accountActions,
    ...subscriberManageActions,
    ...managerActions
}


export const useActions = () => {
    const dispatch = useDispatch()
    return bindActionCreators(actions, dispatch)
}
