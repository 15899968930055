import React from "react";
import { ILabel } from "../../models/models";

const Label = ({name, required} : ILabel) => {
    return (
        <label className="text-black text-xs font-semibold">{name}
            {required && <span className=" text-error1"> * </span>}
        </label>
    )
}

export default Label
