import SuccessChecked from "../components/icons/SuccessChecked";
import BasicButton from "../components/elements/buttons/BasicButton";
import SuccessImg from "../assets/SuccessImg";
import React from "react";
import {useNavigate} from "react-router-dom";
import useAuthSubscriber from "../hooks/auth/useAuthSubscriber";

export function SuccessLogin() {
    
    const navigate = useNavigate()
    useAuthSubscriber()
    
    return (
        <div className=" space-y-3 px-4 grid place-items-center mt-16 w-full md:mt-[140px] mb-[100px] md:mb-[150px]">
            <div className=" flex justify-between items-center">
                <h2 className=" text-black text-lg md:text-2xl font-semibold">Logare cu success!</h2>
                <SuccessChecked />
            </div>

            <div className=" w-[181px] pt-6">
                <BasicButton label="Cabinetul personal" onClick={()=> navigate('/cabinet-personal')} />
            </div>
            <div className=" w-full md:w-[600px] pt-6">
                <SuccessImg />
            </div>
        </div>
    )
}
