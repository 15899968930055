import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {stat} from "fs";

export interface AccountSlice {
    isAuthSubscriber: boolean,
    phone: {
        value: string,
        error: null | string
    },
    techId: {
        value: string,
        error: null | string
    },
    baseId: {
        value: string,
        error: null | string
    },
    expirationDates: {
        value: Array<Date>,
        error: null | string
    },
    email: {
        value: string,
        error: null | string
    },
    techRevisionDates: {
        value: null | Array<Date>,
        error: null | string
    },
    carNumbers: {
        value: null | string[],
        error: null | string
    }
}

const initialState: AccountSlice = {
    isAuthSubscriber : false,
    phone: {
        value: '',
        error: null
    },
    techId: {
        value: '',
        error: null
    },
    baseId: {
        value: '',
        error: null
    },
    expirationDates: {
        value: [],
        error: null
    },
    email: {
        value: '',
        error: null
    },
    techRevisionDates: {
        value: null,
        error: null
    },
    carNumbers: {
        value: null,
        error: null
    }
}

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setIsAuth(state, action: PayloadAction<boolean>) {
          state.isAuthSubscriber = action.payload  
        },
        setPhoneValueAccount(state, action: PayloadAction<string>) {
            state.phone.value = action.payload
        },
        setPhoneErrorAccount(state, action: PayloadAction<string | null>) {
            state.phone.error = action.payload
        },
        setTechIdValueAccount(state, action: PayloadAction<string>) {
            state.techId.value = action.payload
        },
        setTechIdErrorAccount(state, action: PayloadAction<string | null>) {
            state.techId.error = action.payload
        },
        setBaseIdValueAccount(state, action: PayloadAction<string>) {
            state.baseId.value = action.payload
        },
        setBaseIdErrorAccount(state, action: PayloadAction<string | null>) {
            state.baseId.error = action.payload
        },
        setExpirationDateValueAccount(state, action: PayloadAction<Array<Date>>) {
            state.expirationDates.value = action.payload
        },
        updatedExpirationDateValueAccount(state, action: PayloadAction<{key: number, value: Date}>) {
            state.expirationDates.value[action.payload.key] = action.payload.value
        },
        setExpirationDateErrorAccount(state, action: PayloadAction<string | null>) {
            state.expirationDates.error = action.payload
        },
        setCarNumbersValueAccount(state, action: PayloadAction<null | string[]>) {
            state.carNumbers.value = action.payload
        },
        updateCarNumbersValueAccount(state, action: PayloadAction<{key: number, value: string}>) {
            if (state.carNumbers.value)
            state.carNumbers.value[action.payload.key] = action.payload.value
        },
        setCarNumbersErrorAccount(state, action: PayloadAction<null | string>) {
            state.carNumbers.error = action.payload
        },
        setEmailValueAccount(state, action: PayloadAction<string>) {
            state.email.value = action.payload
        },
        setEmailErrorAccount(state, action: PayloadAction<string | null>) {
            state.email.error = action.payload
        },
        setTechRevisionDateValueAccount(state, action: PayloadAction<null | Array<Date>>) {
            state.techRevisionDates.value = action.payload
        },
        updatedTechRevisionDateValueAccount(state, action: PayloadAction<{key: number, value: Date}>) {
            if (state.techRevisionDates.value)
            state.techRevisionDates.value[action.payload.key] = action.payload.value
        },
        setTechRevisionDateErrorAccount(state, action: PayloadAction<string | null>) {
            state.techRevisionDates.error = action.payload
        },
        
    }
})

export const accountActions = accountSlice.actions
export const accountReducer = accountSlice.reducer

