import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Footer } from './components/Footer';
import { Navigation } from './components/Navigation';
import { FaqPage } from './pages/FaqPage';
import { SubscribePage } from "./pages/SubscribePage";
import {SuccessSubscribePage} from "./pages/SuccessSubscribePage";
import {MyAccount} from "./pages/MyAccount";
import {LoginPage} from "./pages/LoginPage";
import {AutoAuth} from "./pages/AutoAuth";
import {SuccessLogin} from "./pages/SuccessLogin";
import {LoginPageAdmin} from './pages/admin/LoginPageAdmin'
import {DashboardPageAdmin} from "./pages/admin/DashoardAdminPage";
import ClientLayout from "./pages/layouts/ClientLayout";
import AdminLayout from "./pages/layouts/AdminLayout";
import {SubscribersPageAdmin} from "./pages/admin/SubscribersAdminPage";

function App() {
  return (
    <>
      {/*<Navigation />*/}
      <Routes>
        <Route path='/' element={ <SubscribePage /> } />
        {/*<Route path='/asigurare' element={ <HomePage /> } />*/}
        <Route path='/faq' element={ <FaqPage /> } />
        {/*<Route path='/personal-data' element={ <PersonalDataPage /> } />*/}
        {/*<Route path='/pay' element={ <PayPage /> } />*/}
        <Route path='/success-subscribe' element={ <SuccessSubscribePage /> } />
        {/*<Route path='/success' element={ <SuccessPage /> } />*/}
          {<Route path='/cabinet-personal' element={<MyAccount /> } />}
        <Route path='/login' element={ <LoginPage /> } />
        <Route path='/auto-auth' element={ <AutoAuth />} />
        <Route path='/login-success' element={ <SuccessLogin />} />
          
        <Route path='/admin' element={ <LoginPageAdmin />} />
        <Route path='/admin/dashboard' element={ <DashboardPageAdmin />} />
        <Route path='/admin/subscribers' element={ <SubscribersPageAdmin />} />
      </Routes>
      {/*<Footer />*/}
    </>
  );
}

export default App;
