import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IOption} from "../../models/models";

export interface HomeSlice {
    baseId: {
        value: string,
        error: null | string
    },
    baseIdSecond: {
        value: string,
        error: null | string
    },
    baseIdThird: {
        value: string,
        error: null | string
    },
    techId: {
        value: string,
        error: null | string
    },
    company: { 
        value: number | string,
        name: string,
    },
    personType: string,
    insuranceType: 'limited' | 'unlimited',
    termsAndConditions: { 
        value: boolean,
        error: null | string
    },
    isSetThirdId: boolean,
    price: number | null
}

const initialState: HomeSlice = {
    baseId: {
        value: '',
        error: null
    },
    baseIdSecond: {
        value: '',
        error: null
    },
    baseIdThird: {
        value: '',
        error: null
    },
    techId: {
        value: '',
        error: null
    },
    company: {
        value: 'moldasig',
        name: 'Moldasig'
    },
    personType: 'physique',
    insuranceType: 'unlimited',
    termsAndConditions: {
        value: false,
        error: null
    },
    isSetThirdId: false,
    price: null
}

export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setBaseIdValue(state, action: PayloadAction<string>) {
            state.baseId.value = action.payload
        },
        setBaseIdError(state, action: PayloadAction<string | null>) {
            state.baseId.error = action.payload
        },
        setBaseIdSecondValue(state, action: PayloadAction<string>) {
            state.baseIdSecond.value = action.payload
        },
        setBaseIdSecondError(state, action: PayloadAction<string | null>) {
            state.baseIdSecond.error = action.payload
        },
        setBaseIdThirdValue(state, action: PayloadAction<string>) {
            state.baseIdThird.value = action.payload
        },
        setBaseIdThirdError(state, action: PayloadAction<string | null>) {
            state.baseIdThird.error = action.payload
        },
        setTechIdValue(state, action: PayloadAction<string>) {
            state.techId.value = action.payload
        },
        setTechIdError(state, action: PayloadAction<string | null>) {
            state.techId.error = action.payload
        },
        setCompany(state, action: PayloadAction<IOption>) {
            state.company = action.payload
        },
        setPersonType(state, action: PayloadAction<'physique' | 'legal'>) {
            state.personType = action.payload
        },
        setInsuranceType(state, action: PayloadAction<'limited' | 'unlimited'>) {
            state.insuranceType = action.payload
        },
        setTermsAndConditions(state, action: PayloadAction<boolean>) {
            state.termsAndConditions.value = action.payload
        },
        setTermsAndConditionsError(state, action: PayloadAction<string | null>) {
            state.termsAndConditions.error = action.payload
        },
        setIsSetThirdId(state, action: PayloadAction<boolean>) {
            state.isSetThirdId = action.payload
        },
        setPrice(state, action: PayloadAction<number | null>) {
            state.price = action.payload
        }
        
    }
})

export const homeActions = homeSlice.actions
export const homeReducer = homeSlice.reducer
