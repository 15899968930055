import Input from "../../components/elements/Input";
import BasicButton from "../../components/elements/buttons/BasicButton";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {useLoginAdminMutation} from "../../store/api/admin.api";
import {ILoginAdminServerResponse} from "../../models/admin/models";
import useLocalStorage from "../../hooks/localStorage";
import {useNavigate} from "react-router-dom";

export function LoginPageAdmin () {

    const {setItem} = useLocalStorage()
    const navigate = useNavigate()

    const [captchaError, setCaptchaError] = useState<string | null>(null)
    const [captchaVal, setCaptchaVal] = useState<string | null>(null)
    const captchaChange = (value: string | null) => {
        setCaptchaVal(value)
        setCaptchaError(null)
    }
    
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const onChangeUsername = (e: React.FormEvent<HTMLInputElement>) => {
        setUsername(e.currentTarget.value)
    }
    const onChangePassword = (e: React.FormEvent<HTMLInputElement>) => {
        setPassword(e.currentTarget.value)
    }
    const [errorLogin, setErrorLogin] = useState<string | null>(null)
    
    const [loginAdmin, {isLoading}] = useLoginAdminMutation()
    
    const onLogin = async () => {
        if (!captchaVal) {
            setCaptchaError('Bifati captcha!')
            return
        }
        if (username.length && password.length) {
            await loginAdmin({
                username,
                password
            }).unwrap().then((payload: ILoginAdminServerResponse) => {
                if (payload.token) {
                    setItem('token', payload.token)
                    navigate('/admin/dashboard')
                } else {
                    setErrorLogin('Username sau parola incorecta')
                }
            }).catch(() => {
                setErrorLogin('Login si parola incorecta!')
            })
        } else {
            setErrorLogin('Introduce-ti loginul si parola!')
        }
    }
    
    return (
        <>
            <div className=" mt-10 px-4 md:px-[165px] md:mt-[130px] mb-[100px] md:mb-[150px] space-y-3">
                <div className=" w-[500px] mx-auto p-[30px] shadow-1">
                        <div className={'space-y-4'}>
                            <h1 className={' p-5 text-center font-semibold text-primeBlue'}>Logare panel control</h1>
                            <span className=" text-error1 text-xs">{errorLogin}</span>
                            <div>
                                <Input type="text" name="usename" placeholder="username" onChange={onChangeUsername} value={username} />
                            </div>
                            <div>
                                <Input type="password" name="password" placeholder="password" onChange={onChangePassword} value={password} />
                            </div>
                            <div>
                                <ReCAPTCHA onChange={captchaChange} sitekey={'6LeEYwgjAAAAAKbm6jgc3jYODYn5pYOzNxOJXp3-'}/>
                                <span className=" text-error1 text-xs">{captchaError}</span>
                            </div>
                            <div className=" py-5">
                                <BasicButton disabled={false} label="Logare!" onClick={onLogin} />
                            </div>
                        </div>
                </div>
            </div>
        </>
    )
}



