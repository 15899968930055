
export default function phoneValidate(value: string): string | null {
    if (value.length < 1) {
        return 'Introduceti nr de telefon'
    }
    if (value.length !== 8) {
        return 'Format incorect!'
    }
    let pattern = /^\d+\.?\d*$/;
    if (!pattern.test(value)) {
        return 'Telefonul trebuie sa contina doar numere'
    }

    return null
}
