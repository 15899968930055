import {useState} from "react";

const useShowMoreLess = () => {
    const [rotate, setRotate] = useState(0)
    const [showMoreFlag, setShowMoreFlag] = useState(false)

    function handleShowLess() {
        (rotate === 0) ? setRotate(180) : setRotate(0)
        setShowMoreFlag(!showMoreFlag)
    }
    
    return {showMoreFlag, rotate, handleShowLess}
}

export default useShowMoreLess
