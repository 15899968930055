
export default function baseIdValidate(value: string): string | null {
    if (value.length !== 13) {
        return 'Format incorect. Idnp trebuie sa contina 13 cifre!'
    }
    let pattern = /^\d+\.?\d*$/;
    if (!pattern.test(value)) {
        return 'Idnp trebuie sa contina doar numere'
    }
    
    return null
}
