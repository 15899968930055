
interface IRotate {
    rotate: number
}
const Arrow = ({ rotate }: IRotate) => {
    return (
        <svg transform={`rotate(${rotate})`} className=" stroke-primeBlue stroke-2" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 1.5L4.5 4.5L1 1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}
 export default Arrow