import Sidebar from "../../components/admin/Sidebar";
import Navbar from "../../components/admin/Navbar";

import "@fortawesome/fontawesome-free/css/all.min.css";
import BasicButton from "../../components/elements/buttons/BasicButton";
import Modal from "../../components/admin/Modal";
import React, {useEffect, useMemo, useState} from "react";
import {useAppSelector} from "../../hooks/redux";
import AddEditSubscriberForm from "../../components/admin/modals/AddEditSubscriberForm";
import {useActions} from "../../hooks/actions";
import {
    useDeleteSubscriberMutation,
    useGetAdminSubscribersQuery,
    useRestoreSubscriberMutation
} from "../../store/api/admin.api";
import DisplayTable from "../../components/admin/DisplayTable";
import {Column} from "react-table";
import {BiEdit, BiTrash, BiReset } from "react-icons/bi";
import Input from "../../components/elements/Input";
import useDebounce from "../../hooks/useDebounce";
import Pagination from "../../components/admin/Pagination";
import PerPageRadioButtons from "../../components/admin/PerPageRadioButtons";
import {DeleteSubscriberResponse, RestoreSubscriberResponse, SaveSubscriberSResponse} from "../../models/admin/models";
import useToast from "../../hooks/toast";
import Checkbox from "../../components/elements/Checkbox";
import {button} from "@material-tailwind/react";

export function SubscribersPageAdmin () {

    const {
        setNameValueAdmin,
        setPhoneValueAdmin,
        setBaseIdValueAdmin,
        setEmailValueAdmin,
        setTechIdValueAdmin,
        setExpirationDatesValueAdmin,
        setTechRevisionDatesValueAdmin,
        setCarNumbersAdmin,
        setIdAdmin
    } = useActions()

    const {openModal, confirmDeleteModal,id, name} = useAppSelector(state => state.subscriberManage)
    const {setOpenModal, setConfirmDeleteModal} = useActions()

    const handleOpenModal = () => {
        setOpenModal(true)
        setNameValueAdmin('')
        setPhoneValueAdmin('')
        setBaseIdValueAdmin('')
        setEmailValueAdmin('')
        setTechIdValueAdmin('')
        setExpirationDatesValueAdmin([new Date()])
        setTechRevisionDatesValueAdmin([new Date()])
        setIdAdmin(null)
        setCarNumbersAdmin([''])
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setIdAdmin(null)
    };

    const handleCloseConfirmDelete = () => {
        setConfirmDeleteModal(false)
        setIdAdmin(null)
    }

    const {manager} = useAppSelector(state => state.manager)

    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 1000);

    const [perPage, setPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
        setSearchTerm(e.currentTarget.value)
        setCurrentPage(1)
    }

    const [onlyTrash, setOnlyTrash] = useState<boolean>(false)

    const {isLoading, isError, data, refetch } = useGetAdminSubscribersQuery({
        search: debouncedSearchTerm,
        page: currentPage,
        perPage: perPage,
        trash: onlyTrash,
    })
    const handleOnlyTrash = (e: React.FormEvent<HTMLInputElement>) => {
        setOnlyTrash(e.currentTarget.checked)
        refetch()
    }

    useEffect(() => {
        refetch()
    }, [onlyTrash])


    const dataTable = data?.items ?? []
    const totalCount = data?.count ?? 0
    const onChangePage = (newPage: number) => {
        setCurrentPage(newPage)
    }
    const handlePerPageChange = (newPerPage: number) => {
        setPerPage(newPerPage)
        setCurrentPage(1)
    };


    const columnsPrepare: Column[] = [
            {
                Header: 'Id',
                accessor: 'id',
            },
            {
                Header: 'Nume/Prenume',
                accessor: 'name',
            },
            {
                Header: 'Manager',
                accessor: 'manager',
            },
            {
                Header: 'Phone',
                accessor: 'phone',
            },
            {
                Header: 'Idnp',
                accessor: 'baseId',
            },
            {
                Header: "Pasaport tehnic",
                accessor: "techId",
            },
            {
                Header: "Data expirarii",
                accessor: "expirationDates",
            },
            {
                Header: "Data expirarii revizie",
                accessor: "techRevisionDates",
            },
            {
                Header: "Nr. masini",
                accessor: "carNumbers",
            },
            {
                Header: "Email",
                accessor: "email",
            },
            {
                Header: "Actions",
                accessor: "",
                Cell: ({ row }) => (
                    <div className={'flex space-x-2'}>
                        <button onClick={() => editSubscriberModal(row.original)}>
                            <BiEdit />
                        </button>
                        <button onClick={() => deleteSubscriberModal(row.original)}>
                            <BiTrash />
                        </button>
                        {onlyTrash && <button onClick={() => restoreSubscriberHandle(row.original)}>
                            <BiReset />
                        </button>}
                    </div>
                ),
            },
        ]

    const columns = useMemo(() => columnsPrepare, [onlyTrash])

    const editSubscriberModal = (subscriber: any) => {
        setIdAdmin(subscriber.id)
        setNameValueAdmin(subscriber.name)
        setPhoneValueAdmin(subscriber.phone)
        setEmailValueAdmin(subscriber.email)
        setBaseIdValueAdmin(subscriber.baseId)
        setTechIdValueAdmin(subscriber.techId)
        let expDatesState = subscriber.expirationDates.map((date: string) => {
            return new Date(date)
        })
        setExpirationDatesValueAdmin(expDatesState)
        let techDatesState = subscriber.techRevisionDates.map((date: string) => {
            return new Date(date)
        })
        setTechRevisionDatesValueAdmin(techDatesState)
        setCarNumbersAdmin(subscriber.carNumbers ?? [''])
        setOpenModal(true)
    }

    const deleteSubscriberModal = (subscriber: any) => {
        setIdAdmin(subscriber.id)
        setConfirmDeleteModal(true)
        setNameValueAdmin(subscriber.name)
    }

    const [restoreSubscriber] = useRestoreSubscriberMutation()
    const restoreSubscriberHandle = async (subscriber: any) => {
        await restoreSubscriber(subscriber.id).unwrap().then((payload: RestoreSubscriberResponse) => {
            toastSuccess('Success')
            refetch()
        }).catch((error) => {
            toastError('Erroare! Contacteaza support!')
        })
    }


    const [deleteSubscriber] = useDeleteSubscriberMutation()
    const {toastSuccess, toastError} = useToast()
    const handleConfirmDelete = async (permanent: boolean) => {
        if (id)
        await deleteSubscriber({
            id,
            permanent
        }).unwrap().then((payload: DeleteSubscriberResponse) => {
            toastSuccess('Success')
            refetch()
            setConfirmDeleteModal(false)
        }).catch((error) => {
            toastError('Erroare! Contacteaza support!')
        })
    }

    return (
        <>
            <Sidebar />
            <Modal isOpen={openModal} onClose={handleCloseModal}>
                <h2 className="text-lg font-bold mb-4">Adaugare client</h2>
                <AddEditSubscriberForm refresh={refetch} />
            </Modal>
            <Modal isOpen={confirmDeleteModal} onClose={handleCloseConfirmDelete}>
                <h2 className={'text-lg pb-4 text-error1'}>Esti sigur ca doresti sa stergi clientul <strong>{name.value}</strong>?</h2>
                <div className={'flex justify-between'}>
                    {!onlyTrash && <BasicButton label={'Trash!'} onClick={() => handleConfirmDelete(false)} />}
                    {manager.role === 'admin' && <BasicButton label={'Stergere definitiva!'} onClick={() => handleConfirmDelete(true)} />}
                </div>

            </Modal>
            <div className="relative md:ml-64 bg-blueGray-100">
                <Navbar />
                <div className="relative bg-pink-600 md:pt-32 pb-32 pt-12">
                    <div className="px-4 md:px-10 mx-auto w-full space-y-2">
                        <div className={'flex space-x-2 w-full'}>
                            <div className={'w-1/5'}>
                                <BasicButton label={'Adauga client'} onClick={handleOpenModal} />
                            </div>
                            <div className={'w-1/5'}>
                                <Input type={'text'} name={'search'} placeholder={'Cautare...'} value={searchTerm} onChange={handleSearch} />
                            </div>
                            {manager.role ==='admin' &&
                                <div className={'w-1/5 flex items-center'}>
                                    <Checkbox id={'only_deleted'} name={'only_deleted'} checked={onlyTrash} value={'only_deleted'} label={'Trash subscribers'} onChange={handleOnlyTrash} />
                                </div>
                            }
                            <div className="flex justify-end w-1/3 items-center">
                                <PerPageRadioButtons options={[5 ,10, 20, 50]} selectedOption={perPage} onOptionChange={handlePerPageChange} />
                            </div>
                        </div>
                        {isLoading && <div>Loading...</div>}
                        {isError && <div>Error</div>}
                        {!isLoading && <DisplayTable columns={columns} data={dataTable} />}
                        {!isLoading && <Pagination totalCount={totalCount} perPage={perPage} currentPage={currentPage} onPageChange={onChangePage} />}
                    </div>
                </div>
            </div>
            
            
        </>
    )
}
