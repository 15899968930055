import React from "react";
import { Link } from "react-router-dom";
import { ILink } from "../../models/models";
 
const AppLink = ({to, text, active}: ILink) => {
    const activeClass = active === true ? " !text-orange underline " : ''
    return (
        <Link className={`" text-sm text-black ${activeClass} font-semibold hover:text-primeBlue hover:underline "`} to={to} >{text}</Link>
    )
}

export default AppLink