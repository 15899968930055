
const useLocalStorage = () => {
    
    function setItem(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }
    
    function getItem(key: string) {
        return JSON.parse(<string>localStorage.getItem(key))
    }

    function removeItem(key: string) {
        localStorage.removeItem(key)
    }
    
    return {setItem, getItem, removeItem}
    
}

export default useLocalStorage
