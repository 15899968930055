import Label from "../elements/Label";
import React from "react";
import {useAppSelector} from "../../hooks/redux";
import DatePicker from "react-datepicker";
import {useUpdateSubscriberFieldMutation} from "../../store/api/my.api";
import {UpdateSubscriberServerResponse} from "../../models/subscribers/models";
import BasicButton from "../elements/buttons/BasicButton";
import {useActions} from "../../hooks/actions";
import {ServerErrorResponse} from "../../models/models";
import useToast from "../../hooks/toast";
import {MdAdd, MdClear} from "react-icons/md";
import Input from "../elements/Input";


export function CarNumberModule() {

    const { carNumbers } = useAppSelector(state => state.account)
    const { setCarNumbersValueAccount, updateCarNumbersValueAccount } = useActions()
    

    const {toastSuccess, toastError} = useToast()
    const [updateSubscriberField, {isLoading}] = useUpdateSubscriberFieldMutation()
    const clickBtn = async () => {
        await updateSubscriberField({
            key: 'car_numbers',
            value: carNumbers.value
        }).unwrap()
            .then((payload: UpdateSubscriberServerResponse) => {
                toastSuccess('Success!')
            }).catch((error: ServerErrorResponse) => {
                toastError('Server error')
            })
    }

    const addCarNumber = () => {
        const newCarNumbers: Array<string> = (carNumbers.value && carNumbers.value.length) ? carNumbers.value.map((value:string) => value) : []
        newCarNumbers.push('')
        setCarNumbersValueAccount(newCarNumbers)
    }
    
    const removeCarNumber = () => {
        const newCarNumbers: Array<string> = (carNumbers.value && carNumbers.value.length) ? carNumbers.value.map((value:string) => value) : []
        newCarNumbers.pop()
        setCarNumbersValueAccount(newCarNumbers)
    }

    const onChangeCarNumber = (key: number,value: string) => {
        updateCarNumbersValueAccount({key, value})
    }

    return (
        <div>
            <div className={'flex space-x-2'}>
                <div className={'w-5/6'}><Label name="Seteaza numarul de masina" /></div>
                {carNumbers.value && carNumbers.value.length < 3 && <BasicButton rounded={true} disabled={isLoading} label={<MdAdd size={24} />} onClick={addCarNumber} />}
            </div>
            
            <div className={'flex space-x-2'}>
                <div className={'w-5/6 space-y-2'}>
                    {
                        carNumbers.value && carNumbers.value.map((value: string, key: number) => {
                            return (
                                <Input type={'text'} name={'car_numbers'} placeholder={'Introduceți numărul mașinii'} value={value} onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    if (e.currentTarget.value.length <= 9)
                                    onChangeCarNumber(key, e.currentTarget.value)
                                }} />
                            )
                        })

                    }
                    <span className=" text-error1 text-xs">{carNumbers.error}</span>
                </div>
                <div className={' flex flex-col justify-end'}>
                    {carNumbers.value && carNumbers.value.length > 1 && <BasicButton rounded={true} disabled={isLoading} label={<MdClear size={24} />} onClick={removeCarNumber} /> }
                </div>
            </div>

            <div className=" w-[50%] py-5">
                <BasicButton disabled={isLoading} label="Actualizează!" onClick={clickBtn} />
            </div>
        </div>
    )
}
