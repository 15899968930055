import Label from "../elements/Label";
import React from "react";
import {useAppSelector} from "../../hooks/redux";
import DatePicker from "react-datepicker";
import {useUpdateSubscriberFieldMutation} from "../../store/api/my.api";
import {UpdateSubscriberServerResponse} from "../../models/subscribers/models";
import BasicButton from "../elements/buttons/BasicButton";
import {useActions} from "../../hooks/actions";
import {ServerErrorResponse} from "../../models/models";
import useToast from "../../hooks/toast";
import {MdAdd, MdClear} from "react-icons/md";


export function TechRevisionDateModule() {

    const { techRevisionDates } = useAppSelector(state => state.account)
    const { setTechRevisionDateValueAccount, updatedTechRevisionDateValueAccount } = useActions()
    

    const {toastSuccess, toastError} = useToast()
    const [updateSubscriberField, {isLoading}] = useUpdateSubscriberFieldMutation()
    const clickBtn = async () => {
        await updateSubscriberField({
            key: 'tech_revision_dates',
            value: techRevisionDates.value
        }).unwrap()
            .then((payload: UpdateSubscriberServerResponse) => {
                toastSuccess('Success!')
            }).catch((error: ServerErrorResponse) => {
                toastError('Server error')
            })
    }

    const addTechDate = () => {
        const newTechDates: Array<Date> = (techRevisionDates.value && techRevisionDates.value.length) ? techRevisionDates.value.map((date:Date) => date) : []
        newTechDates.push(new Date())
        setTechRevisionDateValueAccount(newTechDates)
    }
    
    const removeTechDate = () => {
        const newTechDates: Array<Date> = (techRevisionDates.value && techRevisionDates.value.length) ? techRevisionDates.value.map((date:Date) => date) : []
        newTechDates.pop()
        setTechRevisionDateValueAccount(newTechDates)
    }

    const onChangeDate = (key: number,date: Date) => {
        updatedTechRevisionDateValueAccount({key, value: date})
    }

    return (
        <div>
            <div className={'flex space-x-2'}>
                <div className={'w-5/6'}><Label name="Setați data de expirare reviziei tehnice auto și veți primi notificare prin e-mail sau telefon referitoare la acestea." /></div>
                {techRevisionDates.value && techRevisionDates.value.length < 3 && <BasicButton rounded={true} disabled={isLoading} label={<MdAdd size={24} />} onClick={addTechDate} />}
            </div>
            
            <div className={'flex space-x-2'}>
                <div className={'w-5/6 space-y-2'}>
                    {
                        techRevisionDates.value && techRevisionDates.value.map((date: Date, key: number) => {
                            return (
                                <DatePicker
                                    key={'techDate' + key}
                                    dateFormat="dd.MM.yyyy"
                                    selected={date}
                                    locale="ro"
                                    onChange={(date:Date) => onChangeDate(key, date)}
                                    className=" w-full h-[48px] bg-white p-4 border-[1.5px] border-primaryBlbg rounded-[10px] text-sm placeholder-gray cursor-pointer hover:border-primary2 focus:border-primeBlue focus:text-black"
                                />
                            )
                        })

                    }
                    <span className=" text-error1 text-xs">{techRevisionDates.error}</span>  
                </div>
                <div className={' flex flex-col justify-end'}>
                    {techRevisionDates.value && techRevisionDates.value.length > 1 && <BasicButton rounded={true} disabled={isLoading} label={<MdClear size={24} />} onClick={removeTechDate} /> }
                </div>
            </div>

            <div className=" w-[50%] py-5">
                <BasicButton disabled={isLoading} label="Actualizează!" onClick={clickBtn} />
            </div>
        </div>
    )
}
