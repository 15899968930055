import React from "react";
import { IInput } from "../../models/models";

const Input = ({type, name, placeholder, value, onChange, onFocus, prefix} : IInput) => {
    return (
        <div className=" relative">
            <input 
            className={` ${prefix?.length ? 'pl-10' : ''} outline-0 w-full h-[48px] bg-white p-4 border-[1.5px] border-primaryBlbg rounded-[10px] text-sm placeholder-gray cursor-pointer hover:border-primary2 focus:border-primeBlue focus:text-black`}
            type={type} 
            name={name} 
            placeholder={placeholder} 
            onChange={onChange}
            onFocus={onFocus}
            value={value}
            max="8"
            />
            {prefix?.length && <span className=" absolute left-2 top-[30%] text-gray text-sm">+373</span>}
        </div>
    )
}

export default Input
