import {useEffect, useState} from "react";

const useIsMobile = (): boolean => {
    const [width, setWidth] = useState<number>(window.innerWidth)
    
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, []);
    return width <= 768
}

export default useIsMobile
