import {useLocation, useNavigate} from 'react-router-dom'
import React, {useEffect} from "react";
import {useAutoAuthSubscriberQuery} from '../store/api/my.api'
import useLocalStorage from "../hooks/localStorage";
import {Navigation} from "../components/Navigation";
import {Footer} from "../components/Footer";

export function AutoAuth() {
    
    const navigate = useNavigate()
    const {setItem} = useLocalStorage()
    
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const secret = params.get('secret') ?? ''
    
    
    useEffect(() => {
        if (!secret) {
            navigate('/')
        }
    }, [secret])

    const {isLoading, isError, data} = useAutoAuthSubscriberQuery(secret)
    
    useEffect(() => {
        if (data !== undefined) {
            setItem('token', data.token)
            navigate('/login-success')
            window.location.reload()
        }
    }, [data])
    
    
    return (
        <>
        <Navigation />
        <div className=" space-y-3 px-4 grid place-items-center mt-16 w-full md:mt-[140px] mb-[100px] md:mb-[150px]">
            <div className=" flex justify-between items-center">
                {isError && <h2 className=" text-error1 text-lg md:text-2xl font-semibold">Logare expirata!</h2>}
            </div>
            
        </div>
        <Footer />
        </>
    )
}
