import React from "react";
import { Link } from "react-router-dom";
import logo from '../../src/assets/logo-01.png'

export function Footer() {
    return (
        <footer className=" flex justify-between items-center w-screen fixed bottom-0 px-4 md:px-[165px] h-[43px] md:h-[72px] border-t border-[#F0F4F7] bg-[#F0F4F7]">
            <div className=" w-[70px] hidden md:block">
                <Link to="/"><img src={logo} alt="" /></Link>
            </div>
            <div className=" flex justify-between min-w-full md:min-w-0 md:space-x-8">
                {/*<AppLink to="/instagram" text={"Instagram"} />*/}
                {/*<AppLink to="https://www.facebook.com/365asigmd" text={"Facebook"} />*/}
                <a className={'text-sm text-black font-semibold hover:text-primeBlue hover:underline'} target="_blank" href={'https://www.facebook.com/365asigmd'}>Facebook</a>
                <p className={' text-sm text-black font-semibold hover:text-primeBlue hover:underline'}>Tel: <a href={'tel: +37368171117'}>+37368171117</a></p>
            </div>
        </footer>
    )
}
