import Label from "../../elements/Label";
import Input from "../../elements/Input";
import React from "react";
import {useAppSelector} from "../../../hooks/redux";
import BasicButton from "../../elements/buttons/BasicButton";
import DatePicker, {registerLocale} from "react-datepicker";
import ro from "date-fns/locale/ro";
import {MdAdd, MdClear} from "react-icons/md";
import {useActions} from "../../../hooks/actions";
import phoneValidate from "../../../hooks/validation/phoneValidate";
import baseIdValidate from "../../../hooks/validation/baseIdValidate";
import techIdValidate from "../../../hooks/validation/techIdValidate";
import emailValidate from "../../../hooks/validation/emailValidate";
import {useSaveSubscriberMutation} from "../../../store/api/admin.api";
import {SaveSubscriberSResponse} from "../../../models/admin/models";
import useToast from "../../../hooks/toast";

export interface IAddEditSubscriberProps {
    refresh: () => void
}
const AddEditSubscriberForm = ({refresh}: IAddEditSubscriberProps) => {

    registerLocale("ro", ro)
    const {toastSuccess, toastError} = useToast()
    
    const {
        id,
        name,
        phone, 
        techId, 
        baseId, 
        email,
        techRevisionDates,
        expirationDates,
        carNumbers
    } = useAppSelector(state => state.subscriberManage)
    
    const {
        setNameValueAdmin,
        setNameErrorAdmin,
        setPhoneValueAdmin,
        setPhoneErrorAdmin,
        setBaseIdValueAdmin,
        setBaseIdErrorAdmin,
        setEmailValueAdmin,
        setEmailErrorAdmin,
        setTechIdValueAdmin,
        setTechIdErrorAdmin,
        setExpirationDatesValueAdmin,
        updatedExpirationDatesValueAdmin,
        setExpirationDatesErrorAdmin,
        setTechRevisionDatesValueAdmin,
        updatedTechRevisionDateValueAdmin,
        setTechRevisionDatesErrorAdmin,
        setCarNumbersAdmin,
        updatedCarNumberValueAdmin,
        setCarNumbersErrorAdmin
    } = useActions()

    const onChangeName = (e: React.FormEvent<HTMLInputElement>) => {
        setNameErrorAdmin('')
        if (e.currentTarget.value.length < 6) {
            setNameErrorAdmin('Numele/Prenumele trebuie sa contina mai mult de 6 caracatere')
        }
        setNameValueAdmin(e.currentTarget.value)
    }

    const onChangePhone = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.length <= 8) {
            let error = phoneValidate(e.currentTarget.value)
            setPhoneValueAdmin(e.currentTarget.value)
            setPhoneErrorAdmin(error)
        }
    }
    
    const onChangeBaseId = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.length <= 13) {
            let error = baseIdValidate(e.currentTarget.value)
            setBaseIdValueAdmin(e.currentTarget.value)
            setBaseIdErrorAdmin(error)
        }
    }
    
    const onChangeTechId = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.length <= 9) {
            let error = techIdValidate(e.currentTarget.value)
            setTechIdValueAdmin(e.currentTarget.value)
            setTechIdErrorAdmin(error)
        }
    }
    
    const onChangeEmail = (e: React.FormEvent<HTMLInputElement>) => {
        setEmailValueAdmin(e.currentTarget.value)
        setEmailErrorAdmin(null)
        if (e.currentTarget.value) {
            let error = emailValidate(e.currentTarget.value)
            setEmailErrorAdmin(error)
        }
    }
    
    const onChangeRevisionExpirationDate = (date: Date, key: number) => {
        updatedTechRevisionDateValueAdmin({key, value: date})
    }

    const onChangeExpirationDate = (date: Date, key: number) => {
        updatedExpirationDatesValueAdmin({key, value: date})
    }
    
    const onChangeCarNumber = (value: string, key: number) => {
        updatedCarNumberValueAdmin({key, value})
    }
    
    const addRowData = () => {
        let newTechDates: Array<Date> = techRevisionDates.value.map((date: Date) => date)
        newTechDates.push(new Date())
        setTechRevisionDatesValueAdmin(newTechDates)

        let newExpDates: Array<Date> = expirationDates.value.map((date: Date) => date)
        newExpDates.push(new Date())
        setExpirationDatesValueAdmin(newExpDates)
        
        let newCarNumbers: Array<string> = carNumbers.value.map((carNumber: string) => carNumber)
        newCarNumbers.push('')
        setCarNumbersAdmin(newCarNumbers)
    }
    
    const removeRowData = () => {
        let newTechDates: Array<Date> = techRevisionDates.value.map((date: Date) => date)
        newTechDates.pop()
        setTechRevisionDatesValueAdmin(newTechDates)

        let newExpDates: Array<Date> = expirationDates.value.map((date: Date) => date)
        newExpDates.pop()
        setExpirationDatesValueAdmin(newExpDates)

        let newCarNumbers: Array<string> = carNumbers.value.map((carNumber: string) => carNumber)
        newCarNumbers.pop()
        setCarNumbersAdmin(newCarNumbers)
    }


    const [saveSubscriber, {isLoading}] = useSaveSubscriberMutation()
    const saveSubscriberHandle = async () => {
        await saveSubscriber({
            id,
            name: name.value,
            phone: phone.value,
            id_np: baseId.value,
            tech_id: techId.value,
            email: email.value,
            expiration_dates: expirationDates.value,
            tech_revision_dates: techRevisionDates.value,
            car_numbers: carNumbers.value,
        }).unwrap().then((payload: SaveSubscriberSResponse) => {
            toastSuccess('Success')
            refresh()
        }).catch((error) => {
            toastError('Erroare! Contacteaza support!')
        })
    }


    return (
        <div className=" space-y-4 w-full">
            <div>
                <Label name={'Numele/Prenumele'} />
                <Input type={'text'} name={'name'} placeholder={'Introduce-ti numele/prenumele'} value={name.value} onChange={onChangeName} />
                <span className=" text-error1 text-xs">{name.error}</span>
            </div>
            <div className="flex flex-wrap w-full justify-between">
                <div className=" space-y-2 w-1/2 px-2">
                    <Label name="Nr. de telefon" required />
                    <Input prefix="+373" type="text" name="phone" placeholder="Introduceti nr de telefon" onChange={onChangePhone} value={phone.value} />
                    <span className=" text-error1 text-xs">{phone.error}</span>
                </div>
                <div className=" space-y-2 w-1/2 px-2">
                    <Label name="Idnp" required />
                    <Input type="text" name="baseId" placeholder="Introduceti idnp" onChange={onChangeBaseId} value={baseId.value} />
                    <span className=" text-error1 text-xs">{baseId.error}</span>
                </div>
            </div>
            <div className="flex flex-wrap w-full justify-between">
                <div className=" space-y-2 w-1/2 px-2">
                    <Label name="Id pasaport tehnic" required />
                    <Input type="text" name="techId" placeholder="Introduceti id pasaport tehnic" onChange={onChangeTechId} value={techId.value} />
                    <span className=" text-error1 text-xs">{techId.error}</span>
                </div>
                <div className=" space-y-2 w-1/2 px-2">
                    <Label name="Email" required />
                    <Input type="text" name="baseId" placeholder="Introduceti email" onChange={onChangeEmail} value={email.value} />
                    <span className=" text-error1 text-xs">{email.error}</span>
                </div>
            </div>
            
            <hr className={' text-gray px-2 py-4 mt-4'}/>
            <div className={'px-2 flex justify-between'}>
                <BasicButton label={<MdAdd />} onClick={addRowData} />
                <BasicButton label={<MdClear />} onClick={removeRowData} />
            </div>

            <div className="flex flex-wrap w-full justify-between">
                <div className=" space-y-2 w-1/3 px-2">
                    <Label name="Data expirarii revizie tehnica" />
                    {
                        techRevisionDates.value && techRevisionDates.value.map((dateSet: Date, key: number) => {
                            return (
                                <DatePicker
                                    key={'techdate-' + key}
                                    placeholderText={'Data expirarii'}
                                    dateFormat="dd.MM.yyyy"
                                    selected={dateSet}
                                    locale="ro"
                                    onChange={(date:Date) => onChangeRevisionExpirationDate(date, key)}
                                    className=" w-full h-[48px] bg-white p-4 border-[1.5px] border-primaryBlbg rounded-[10px] text-sm placeholder-gray cursor-pointer hover:border-primary2 focus:border-primeBlue focus:text-black"
                                />
                            )
                        })
                    }
                    <span className=" text-error1 text-xs">{techRevisionDates.error}</span>
                </div>
                <div className=" space-y-2 w-1/3 px-2">
                    <Label name="Data expirarii asigurare" />
                    {
                        expirationDates.value && expirationDates.value.map((dateSet: Date, key: number) => {
                            return (
                                <DatePicker
                                    key={'expdate-' + key}
                                    placeholderText={'Data expirarii'}
                                    dateFormat="dd.MM.yyyy"
                                    selected={dateSet}
                                    locale="ro"
                                    onChange={(date:Date) => onChangeExpirationDate(date, key)}
                                    className=" w-full h-[48px] bg-white p-4 border-[1.5px] border-primaryBlbg rounded-[10px] text-sm placeholder-gray cursor-pointer hover:border-primary2 focus:border-primeBlue focus:text-black"
                                />
                            )
                        })
                    }
                    <span className=" text-error1 text-xs">{techRevisionDates.error}</span>
                </div>
                <div className={' space-y-2 w-1/3 px-2'}>
                    <Label name="Nr masinii" />
                    {
                        carNumbers.value && carNumbers.value.map((carNumber: string, key: number) => {
                            return (
                                <Input 
                                    key={'car-number-' + key} 
                                    type="text" name="carNumber" 
                                    placeholder="Nr masinii" 
                                    onChange={(e: React.FormEvent<HTMLInputElement>) => onChangeCarNumber(e.currentTarget.value, key)} 
                                    value={carNumber} />
                            )
                        })
                    }
                    <span className=" text-error1 text-xs">{carNumbers.error}</span>
                </div>
            </div>
            
            <div>
                <BasicButton disabled={isLoading} label="Salveaza!" onClick={saveSubscriberHandle} />
            </div>
        </div>
    )
}

export default AddEditSubscriberForm
