import React, {useEffect, useState} from "react";
import Input from "../components/elements/Input";
import BasicButton from "../components/elements/buttons/BasicButton";
import emailValidate from "../hooks/validation/emailValidate";
import {useAuthSubscriberQuery, useLoginSubscriberMutation, usePasscodeLoginMutation} from "../store/api/my.api"
import {AutoAuthServerResponse, LoginSubscriberServerResponse} from "../models/subscribers/models";
import {ServerErrorResponse} from "../models/models";
import {useNavigate} from "react-router-dom";
import phoneValidate from "../hooks/validation/phoneValidate";
import useToast from "../hooks/toast";
import passcodeValidate from "../hooks/validation/passcodeValidate";
import useLocalStorage from "../hooks/localStorage";
import useTimer from "../hooks/useTimer";
import {Footer} from "../components/Footer";
import {Navigation} from "../components/Navigation";

export function LoginPage() {

    const navigate = useNavigate()
    
    // check is auth
    const {isLoading: isLoadingAuth, isError, data} = useAuthSubscriberQuery('')
    useEffect(() => {
        if (!isError && !isLoadingAuth) {
            navigate('/cabinet-personal')
        }
    }, [isError, isLoadingAuth])
    
    
    // set state variables
    const [errorLogin, setErrorLogin] = useState<string | null>(null)
    const [errorLoginPasscode, setErrorLoginPasscode] = useState<string | null>(null)
    const [email, setEmail] = useState<string>('')
    const [phone, setPhone] = useState<string>('')
    const [passcode, setPasscode] = useState<string>('')
    
    const onChangeEmail = (e: React.FormEvent<HTMLInputElement>) => {
        let error = emailValidate(e.currentTarget.value)
        setErrorLogin(error)
        setEmail(e.currentTarget.value)
    }
    const onChangePhone = (e: React.FormEvent<HTMLInputElement>) => {
        let error = phoneValidate(e.currentTarget.value)
        setErrorLogin(error)
        setPhone(e.currentTarget.value)
    }
    const onChangePassCode = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.length <= 6) {
            setPasscode(e.currentTarget.value)
            let error = passcodeValidate(e.currentTarget.value)
            setErrorLogin(error)
        }
    }
    
    const [loginType, setLoginType] = useState<'email' | 'sms'>('email')
    
    const [loginSubscriber, {isLoading}] = useLoginSubscriberMutation()
    const [passcodeLogin, {isLoading: isLoadingPass}] = usePasscodeLoginMutation()
    const {toastSuccess, toastError} = useToast()
    
    const [loginState, setLoginState] = useState<'login' | 'passcode'>('login')
    const [time, setTime] = useState(120)
    let {seconds, reset} = useTimer({time})
    
    
    const clickBtn = async () => {
        if (!errorLogin) {
             await loginSubscriber({
                 email: email,
                 phone: parseInt(phone),
                 type: loginType
             }).unwrap().then((payload: LoginSubscriberServerResponse) => {
                 if (loginType === 'email') {
                     toastSuccess('Verifica emailul')
                     setLoginState('login')
                 } else {
                     toastSuccess ('Verifica sms')
                     setLoginState('passcode')
                     reset({time: 120})   
                 }
             }).catch((error: ServerErrorResponse) => {
                 toastError('Eroare la logare!')
                 setErrorLogin('Eroare! Incercati mai tirziu sau contactatine!')
             }) 
        }
    }

    const {setItem} = useLocalStorage()
    
    const passCodeLoginClick = async () => {
        if (!errorLoginPasscode) {
            await passcodeLogin({
                phone: Number(phone),
                passCode: Number(passcode)
            }).unwrap().then((payload: AutoAuthServerResponse) => {
                
                if (payload !== undefined) {
                    setItem('token', payload.token)
                    navigate('/login-success')
                    window.location.reload()
                }
            }).catch((error: ServerErrorResponse) => {
                toastError('Eroare la logare!')
                setErrorLoginPasscode('Eroare! Incercati mai tirziu sau contactatine!')
            })
        }
    }
    
    const back = () => {
        setLoginState('login')
    }
    
    return (
        <>
        <Navigation />
        <div className=" mt-10 px-4 md:px-[165px] md:mt-[130px] mb-[100px] md:mb-[150px] space-y-3">
            <div className=" w-[500px] mx-auto p-[30px] shadow-1">
                
                { 
                    loginState === 'login' &&
                    <div>
                        <h1 className={' p-5 text-center font-semibold text-primeBlue'}>Logare</h1>
                        <div className=" flex justify-between py-5 text-sm font-semibold">
                            <a className={` ${loginType === 'sms' ? 'text-primary2 underline' : ''} `} onClick={() => setLoginType('email')} href="#">Prin email</a>
                            <a className={` ${loginType === 'email' ? 'text-primary2 underline' : ''} `} onClick={() => setLoginType('sms')} href="#">Prin sms</a>
                        </div>
                        { loginType === 'email' &&
                        <div>
                            <Input type="text" name="email" placeholder="Introduceti emailul" onChange={onChangeEmail} value={email} />
                        </div>
                        }
                        { loginType === 'sms' &&
                        <div>
                            <Input prefix="+373" type="text" name="phone" placeholder="Introduceti nr de telefon" onChange={onChangePhone} value={phone} />
                        </div>
                        }
                        <span className=" text-error1 text-xs">{errorLogin}</span>
                        <div className=" py-5">
                            <BasicButton disabled={isLoading} label="Logare!" onClick={clickBtn} />
                        </div>
                    </div>
                }
                {
                    loginState === 'passcode' &&
                    <div>
                        <div className={'py-2 text-sm text-primary2 text-center'}>
                            <a className={'underline'} onClick={back} href="#">Inapoi</a>
                        </div>
                        <div>
                            <Input type={'text'} name={'passcode'} placeholder={'Sms code'} value={passcode} onChange={onChangePassCode} />
                        </div>
                        <span className={'text-error1 text-xs'}>{ errorLogin }</span>
                        <div className=" py-2 text-center text-primary2">
                            {seconds > 0 && <p className={'text-sm'}>Timp ramas: {seconds} secunde</p>}
                            {seconds <= 1 && <a className={' text-sm underline'} href="#" onClick={clickBtn}>Retransmite</a>}
                        </div>
                        <div className=" py-2">
                            <BasicButton disabled={isLoading} label="Confirm!" onClick={passCodeLoginClick} />
                        </div>
                    </div>
                }
                
            </div>
        </div>
        <Footer />
        </>
    )
}
