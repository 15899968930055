import { toast } from 'react-toastify'

const useToast = () => {
    
    const toastError = (message: string | []) => {
        if (message instanceof Array) {
            message.forEach(m => {
                toast.error(m, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
        } else {
            toast.error(message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        
    }
    
    const toastSuccess = (message: string) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }
    
    return {toastError, toastSuccess}
    
}

export default useToast
